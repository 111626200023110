import { createStore, applyMiddleware } from 'redux'
import { rootReducer, initialState } from '../Reducers'
import thunk from 'redux-thunk'
import * as SignalR from '@aspnet/signalr'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import config from '../Services/config'
import * as types from '../Actions/actionTypes'
import { signalRActions, priceIndicationActions, notificationActions, offerActions } from '../Actions'
import { userDataStorageUtils, dateUtils, redirectUtils } from '../Utils'
import { alertService } from '../Services'

const connectionSignalR = new SignalR.HubConnectionBuilder()
    .withUrl(config.signalRURL, { accessTokenFactory: () => userDataStorageUtils.getAuth() }).build()

const connectionNotifications = new SignalR.HubConnectionBuilder()
    .withUrl(config.notificationHubURL, { accessTokenFactory: () => userDataStorageUtils.getAuth() }).build()


const signalRInvokeMiddleware = store => {
    return next => async action => {
        switch (action.type) {
            case types.REQUEST_SIGNALR_CONNECTION:
                startConnection(store)
                break;
            case types.CHECK_CMA_CONNECTION:
                connectionSignalR.invoke('isCMAConnected')
                    .then(isConnected => {
                        store.dispatch(signalRActions.checkCMAConnectionSuccess(isConnected))
                    })
                    .catch(error => {
                        store.dispatch(signalRActions.checkCMAConnectionError(error))
                    })
                break;
            case types.SUBSCRIBE_COTATIONS:
                action.payload.entities.forEach(entity => {
                    const cbotId = entity.price ? entity.price.cbotId : entity.cbotId
                    if (cbotId) {
                        connectionSignalR.invoke('SubscribeCotationByCBOTId', cbotId)
                            .then(result => {
                                if (!result) debugger
                                store.dispatch(signalRActions.subscribeCotationsSuccess({ [action.payload.controlVariable]: result }))
                            })
                            .catch(e => {
                                store.dispatch(signalRActions.subscribeCotationsError({ [action.payload.controlVariable]: false }))
                                userDataStorageUtils.removeAuth()
                                redirectUtils.redirectTo('#/')
                                alertService.error('', 'Erro buscar cotação da bolsa')
                            })
                    }
                });
                break;
            case types.SUBSCRIBE_EXCHANGES:
                action.payload.entities.forEach(entity => {
                    const paymentOn = entity.price ? entity.price.paymentOn : entity.paymentOn
                    const tradeOn = entity.price ? entity.price.tradeOn : entity.tradeOn
                    if (tradeOn) {
                        connectionSignalR.invoke('SubscribeDollarByDate', dateUtils.format(paymentOn, 'YYYY-MM-DD', 'DD/MM/YY'))
                            .then(result => {
                                if (!result) debugger
                                store.dispatch(signalRActions.subscribeExchangesSuccess({ [action.payload.controlVariable]: result }))
                            })
                            .catch(() => {
                                store.dispatch(signalRActions.subscribeExchangesError({ [action.payload.controlVariable]: false }))
                                userDataStorageUtils.removeAuth()
                                redirectUtils.redirectTo('#/')
                                alertService.error('', 'Erro ao buscar curva do dolar')
                            })
                    }
                });
                break;
            case types.UNSUBSCRIBE_COTATIONS:
                action.payload.entities.forEach(entity => {
                    const cbotId = entity.price ? entity.price.cbotId : entity.cbotId
                    if (cbotId) {
                        connectionSignalR.invoke('UnsubscribeCotationByCBOTId', cbotId)
                            .then(result => {
                                store.dispatch(signalRActions.unsubscribeCotationsSuccess(action.payload.controlVariable))
                            })
                            .catch(() => {
                                store.dispatch(signalRActions.unsubscribeCotationsError())
                                userDataStorageUtils.removeAuth()
                                redirectUtils.redirectTo('#/')
                                alertService.error('', 'Erro buscar cotação da bolsa')
                            })
                    }
                })
                break;
            case types.UNSUBSCRIBE_EXCHANGES:
                action.payload.entities.forEach(entity => {
                    const paymentOn = entity.price ? entity.price.paymentOn : entity.paymentOn
                    const tradeOn = entity.price ? entity.price.tradeOn : entity.tradeOn
                    if (tradeOn) {
                        connectionSignalR.invoke('UnsubscribeDollarByDate', dateUtils.format(paymentOn, 'YYYY-MM-DD', 'DD/MM/YYYY'))
                            .then(result => {
                                store.dispatch(signalRActions.unsubscribeExchangesSuccess(action.payload.controlVariable))
                            })
                            .catch(e => {
                                store.dispatch(signalRActions.unsubscribeExchangesError())
                                userDataStorageUtils.removeAuth()
                                redirectUtils.redirectTo('#/')
                                alertService.error('', 'Erro ao buscar curva do dolar')
                            })
                    }
                })
                break;
            case types.CLOSE_SIGNALR_CONNECTION:
                connectionSignalR.stop().then(() => {})
                break;
            case types.UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES:
                connectionSignalR.invoke('UnsubscribeAllCotations')
                    .then(() => {
                        store.dispatch(signalRActions.unsubscribeAllCotationsAndExchangesSuccess())
                    })
                    .catch(() => {
                        store.dispatch(signalRActions.unsubscribeAllCotationsAndExchangesError())
                    })
                break;
            default:
                break;
        }

        return next(action);
    }
}

const startConnection = store => {
    if (!connectionSignalR.methods.newcotationupdate) {
        connectionSignalR.on('NewCotationUpdate', (cbotId, symbol, cotation) => {
            const state = store.getState()
            if (state.signalR.todayOffersCotationSubscribed)
                store.dispatch(offerActions.cotationUpdate(cbotId, cotation))
            if (state.signalR.indicationsCotationSubscribed)
                store.dispatch(priceIndicationActions.cotationUpdate(cbotId, cotation))
        })

        connectionSignalR.on('NewDollarUpdate', (date, exchange) => {
            const state = store.getState()
            const formattedDate = dateUtils.formatToUTC(date)
            if (state.signalR.todayOffersExchangeSubscribed)
                store.dispatch(offerActions.exchangeUpdate(formattedDate, exchange))
            if (state.signalR.indicationsExchangeSubscribed)
                store.dispatch(priceIndicationActions.exchangeUpdate(formattedDate, exchange))
        })

        connectionSignalR.on('LoginResponse', (serverUtcNow, exchanges) => {
            const cbot = exchanges.find(ex => ex.symbol === 'M')
            const dollar = exchanges.find(ex => ex.symbol === 'E')

            store.dispatch(signalRActions.setExchangesInfo({
                cbot,
                dollar
            }))
        })

        connectionSignalR.on('CMAStatusChanged', status => {
            console.log('CMA status changed', status);
        })

        connectionSignalR.on('MarketExchangeStatusChanged', exchange => {
            const name = exchange.symbol === 'M' ? 'cbot' : 'dollar'
            store.dispatch(signalRActions.setExchangesInfo({ [name]: { ...exchange } }))
        })

        connectionSignalR.on('PriceUpdated', harvestIds => {
            const state = store.getState()
            store.dispatch(signalRActions.unsubscribeCotations(state.pricesIndications.entities, state.pricesIndications.cotationControl))
            store.dispatch(signalRActions.unsubscribeExchanges(state.pricesIndications.entities, state.pricesIndications.exchangeControl))
            store.dispatch(priceIndicationActions.fetchPublishedPrices({ harvestId: state.pricesIndications.currentHarvestId }, { isPriceConnected: state.signalR.isPriceConnected, cotationControl: state.pricesIndications.cotationControl, exchangeControl: state.pricesIndications.exchangeControl }))
        })

    }

    if (!connectionNotifications.methods.unreadnotificationcountchanged) {
        connectionNotifications.on('UnreadNotificationCountChanged', notificationCount => {
            store.dispatch(notificationActions.fetchNotifications())
            store.dispatch(offerActions.fetchTodayOffers())
        })
    }

    connectionNotifications.start()
        .then(() => {
            store.dispatch(signalRActions.requestConnectionSuccess())
        })
        .catch(error => {
            store.dispatch(signalRActions.requestConnectionError(error))
        })

    connectionSignalR.start()
        .then(() => {
            store.dispatch(signalRActions.requestConnectionSuccess())
        }).catch(error => {
            store.dispatch(signalRActions.requestConnectionError(error))
        })
}

const middleware = [thunk, signalRInvokeMiddleware];

if (process.env.NODE_ENV !== 'production')
    middleware.push(createLogger());

export default function configureStore() {
    return createStore(
        rootReducer,
        initialState,
        composeWithDevTools(applyMiddleware(...middleware)));
}
