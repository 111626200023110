import initialState from '../initialState'
import {
    REQUEST_TODAY_OFFERS,
    REQUEST_TODAY_OFFERS_SUCCESS,
    REQUEST_TODAY_OFFERS_ERROR,
    REQUEST_OFFER,
    REQUEST_OFFER_SUCCESS,
    REQUEST_OFFER_ERROR,
    REQUEST_CANCEL_OFFER,
    REQUEST_CANCEL_OFFER_SUCCESS,
    REQUEST_CANCEL_OFFER_ERROR,
    OFFERS_COTATION_UPDATE,
    OFFERS_EXCHANGE_UPDATE,
    REQUEST_NEGOTIATION_CONTRACT,
    REQUEST_NEGOTIATION_CONTRACT_SUCCESS,
    REQUEST_NEGOTIATION_CONTRACT_ERROR,
    HANDLE_OFFER_VALUE_CHANGE
} from '../../Actions/actionTypes'
import { calculateUpdatedValue } from '../../Utils'

const requestTodayOffers = (state, action) => {
    return { ...state, isFetching: true, entities: [] };
};

const requestTodayOffersSuccess = (state, action) => {
    return {
        ...state,
        entities: action.payload,
        isFetching: false
    };
};

const requestTodayOffersError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestOffer = (state, action) => {
    return { ...state, isFetching: true };
};

const requestOfferSuccess = (state, action) => {
    return { ...state, isFetching: false, detail: action.payload };
};

const requestOfferError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestCancelOffer = (state, action) => {
    return { ...state, isDeleting: true };
};

const requestCancelOfferSuccess = (state, action) => {
    return { ...state, isDeleting: false, entities: state.entities.filter(offer => offer.id !== action.payload.id) };
};

const requestCancelOfferError = (state, action) => {
    return { ...state, isDeleting: false };
};

const cotationUpdate = (state, action) => {
    const updatedCotation = action.payload.cotation
    const entities = state.entities.map(offer => {
        if (offer.price && offer.price.cbotId === action.payload.cbotId) {
            return {
                ...offer,
                price: {
                    ...offer.price,
                    updatedCotation,
                    updatedValue: calculateUpdatedValue(offer.price, updatedCotation, offer.price.updatedExchangeFee)
                }
            }
        }
        return offer
    })
    return { ...state, entities };
};

const exchangeUpdate = (state, action) => {
    const updatedExchangeFee = action.payload.exchange
    const entities = state.entities.map(offer => {
        if (offer.price && offer.price.paymentOn === action.payload.date) {
            return {
                ...offer,
                price: {
                    ...offer.price,
                    updatedExchangeFee,
                    updatedValue: calculateUpdatedValue(offer.price, offer.price.updatedCotation, updatedExchangeFee)
                }
            }
        }
        return offer
    })
    return { ...state, entities }
}

const requestContractFile = (state, action) => {
    return { ...state, isFetching: true };
};
const requestContractFileSuccess = (state, action) => {
    return { ...state, isFetching: false };
};
const requestContractFileError = (state, action) => {
    return { ...state, isFetching: false };
};

const handleValueChange = (state, action) => {
    return {
        ...state,
        ...action.payload,
    };
};

const actions = {
    [REQUEST_TODAY_OFFERS]: requestTodayOffers,
    [REQUEST_TODAY_OFFERS_SUCCESS]: requestTodayOffersSuccess,
    [REQUEST_TODAY_OFFERS_ERROR]: requestTodayOffersError,
    [REQUEST_OFFER]: requestOffer,
    [REQUEST_OFFER_SUCCESS]: requestOfferSuccess,
    [REQUEST_OFFER_ERROR]: requestOfferError,
    [REQUEST_CANCEL_OFFER]: requestCancelOffer,
    [REQUEST_CANCEL_OFFER_SUCCESS]: requestCancelOfferSuccess,
    [REQUEST_CANCEL_OFFER_ERROR]: requestCancelOfferError,
    [OFFERS_COTATION_UPDATE]: cotationUpdate,
    [OFFERS_EXCHANGE_UPDATE]: exchangeUpdate,
    [REQUEST_NEGOTIATION_CONTRACT]: requestContractFile,
    [REQUEST_NEGOTIATION_CONTRACT_SUCCESS]: requestContractFileSuccess,
    [REQUEST_NEGOTIATION_CONTRACT_ERROR]: requestContractFileError,
    [HANDLE_OFFER_VALUE_CHANGE]: handleValueChange,
};

export const offers = (state = initialState.offers, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
