export default {
  auth: {
    token: "",
    isFetching: false,
    changedTempPass: false,
    user: {},
    termsOfService: "",
  },
  signalR: {
    isConnecting: false,
    isConnected: false,
    isCMAConnected: false,
    pricesCotationSubscribed: false,
    pricesExchangeSubscribed: false,
    indicationsCotationSubscribed: false,
    indicationsExchangeSubscribed: false,
    todayOffersCotationSubscribed: false,
    todayOffersExchangeSubscribed: false,
    serverUtcNow: "",
    exchanges: {
      cbot: {
        closesOn: "",
        isOpen: true,
      },
      dollar: {
        closesOn: "",
        isOpen: true,
      },
    },
  },
  harvests: {
    isFetching: false,
    entities: [],
    options: [],
  },
  pricesIndications: {
    entities: [],
    productFilter: 1,
    currentHarvestId: null,
    currentProductId: null,
    cotationControl: "indicationsCotationSubscribed",
    exchangeControl: "indicationsExchangeSubscribed",
    isFetching: false,
    isSaving: false,
  },
  offers: {
    isFetching: false,
    isDeleting: false,
    isSaving: false,
    cotationControl: "todayOffersCotationSubscribed",
    exchangeControl: "todayOffersExchangeSubscribed",
    entities: [],
    detail: {
      product: {},
      price: {},
    },
  },
  offersHistory: {
    isFetching: false,
    entities: [],
    top: 15,
    currentPage: 1,
    count: 0,
    productFilter: 1,
  },
  offerForm: {
    isSaving: false,
    harvestId: 0,
    quantity: "",
    value: 0,
    paymentOn: "",
    immediateDelivery: false,
    deliveryStartsOn: "",
    deliveryEndsOn: "",
    destinationId: 0,
    shippingAgreementTypeId: 0,
    portTerminalId: 0,
    priceId: "",
    duration: 10,
    dirtRoadLength: "",
    useMarketTime: "false",
    ufCode: "",
    product: {},
    isSaveComplete: false,
  },
  counterOffers: {
    isSaving: false,
  },
  notifications: {
    isFetching: false,
    entities: [],
  },
  cities: {
    isFetching: false,
    options: [],
    suggestedOptions: [],
  },
  portTerminals: {
    isFetching: false,
    options: [],
  },
  shippingAgreementTypes: {
    isFetching: false,
    options: [],
  },
  userPreRegisterForm: {
    isSaving: false,
    isSaveComplete: false,
    id: "",
    statusName: "",
    cpf: "",
    name: "",
    email: "",
    phoneNumber: "",
    bornOn: "",
    clientActivityType: "",
    clientName: "",
    clientCPFCNPJ: "",
  },
  userChangePassForm: {
    isSaving: false,
    currentPassword: "",
    newPassword: "",
  },
  activityTypes: {
    isFetching: false,
    options: [],
  },
};
