import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../../Actions'
import Loader from 'react-loader'

export default function TermsOpenContainer() {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    useEffect(() => {
        dispatch(authActions.fetchTermsOpen())
    }, [auth.termsOfService, dispatch])

    return (
        <div className='terms-open-container font-white'>
            <Loader loaded={!auth.isFetching} color='#fff'>
                <div dangerouslySetInnerHTML={{ __html: auth.termsOfService }}>
                </div>
            </Loader>
        </div>
    )
}
