import BaseService from "./base.service";

class UserService extends BaseService {
  constructor() {
    super("User");
  }

  changePassword(values) {
    return this.post(values, "/ChangePassword");
  }

  forgotPassword(values) {
    return this.post(values, "/forgotPassword");
  }
}

export default UserService;
