import initialState from './initialState';
import { REQUEST_PORT_TERMINALS, REQUEST_PORT_TERMINALS_SUCCESS, REQUEST_PORT_TERMINALS_ERROR } from '../Actions/actionTypes';

const requestPortTerminals = (state, action) => {
    return { ...state, isFetching: true };
};

const requestPortTerminalsSuccess = (state, action) => {
    return {
        ...state,
        options: action.payload,
        isFetching: false
    };
};

const requestPortTerminalsError = (state, action) => {
    return { ...state, isFetching: false };
};

const actions = {
    [REQUEST_PORT_TERMINALS]: requestPortTerminals,
    [REQUEST_PORT_TERMINALS_SUCCESS]: requestPortTerminalsSuccess,
    [REQUEST_PORT_TERMINALS_ERROR]: requestPortTerminalsError
};

export const portTerminals = (state = initialState.portTerminals, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
