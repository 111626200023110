import { combineReducers } from "redux";
import { auth } from "./authReducer";
import { signalR } from "./signalRReducer";
import { harvests } from "./harvestReducer";
import { pricesIndications } from "./Prices/priceIndicationReducer";
import { offers } from "./Offers/offerReducer";
import { offersHistory } from "./Offers/offerHistoryReducer";
import { offerForm } from "./Offers/offerFormReducer";
import { cities } from "./cityReducer";
import { portTerminals } from "./portTerminalReducer";
import { shippingAgreementTypes } from "./shippingAgreementTypeReducer";
import { userPreRegisterForm } from "./userPreRegisterFormReducer";
import { userChangePassForm } from "./userChangePassReducer";
import { activityTypes } from "./activityTypesReducer";
import { notifications } from "./notificationReducer";
import { counterOffers } from "./Offers/counterOfferReducer";

const rootReducer = combineReducers({
  auth,
  signalR,
  harvests,
  pricesIndications,
  offers,
  offersHistory,
  offerForm,
  cities,
  portTerminals,
  shippingAgreementTypes,
  userPreRegisterForm,
  userChangePassForm,
  activityTypes,
  notifications,
  counterOffers,
});

export default rootReducer;
