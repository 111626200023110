import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthService } from '../Services'
const authService = new AuthService()

function getRedirectComponent(props) {
  let pathName;
  if (authService.isAuthenticated()) {
    pathName = '/';
  } else {
    pathName = '/login';
  }

  const toObject = {
    pathname: pathName,
    state: {
      from: props.location
    }
  };

  return <Redirect to={toObject} />;
}

function getRenderRouteFunction(Component) {
  return props =>
    authService.isAuthenticated() ? (
      <Component {...props} />
    ) : (
        getRedirectComponent(props)
      );
}

export default function PrivateRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={getRenderRouteFunction(Component)} />;
}
