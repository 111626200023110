import React, { useState } from 'react'
import Loader from 'react-loader'
import { useForm } from '../../Hooks'

export default function FirstAccessForm({ onFirstAccess, isFetching }) {
    const [inputType, changeInputType] = useState('password')
    const [secondInputType, changeSecondInputType] = useState('password')
    const { values, handleChange, handleSubmit } = useForm({ password: '', repeatPass: '' }, () => onFirstAccess(values));

    const toggleVisibility = (variable, handler) => {
        if (variable === 'password')
            handler('text')
        else
            handler('password')
    }

    return (
        <div className='first-access-form-container'>
            <Loader color='#fff' loaded={!isFetching}>
                <div className='info-row'>
                    <span>
                        Crie uma senha para acesso ao aplicativo. <br />
                        Deve conter no mínimo 6 caracteres.
                    </span>
                </div>
                <form onSubmit={e => handleSubmit(e)}>
                    <div className='form-row'>
                        <label>Senha</label>
                        <div className='input-container'>
                            <input className='inova-input' name='password' type={inputType} onChange={handleChange} />
                            <i className='ic-eye' onClick={() => toggleVisibility(inputType, changeInputType)} />
                        </div>
                    </div>
                    <div className='form-row'>
                        <label>Repetir Senha</label>
                        <div className='input-container'>
                            <input className='inova-input' name='repeatPass' type={secondInputType} onChange={handleChange} />
                            <i className='ic-eye' onClick={() => toggleVisibility(secondInputType, changeSecondInputType)} />
                        </div>
                    </div>
                    <div className='btn-row'>
                        <button className='btn inova-btn primary' disabled={values.repeatPass.length < 6 || !(values.repeatPass === values.password)}>Continuar</button>
                    </div>
                </form>
            </Loader>
        </div>
    )
}
