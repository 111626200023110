import moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-BR')

class DateUtils {
  newDate(date, dateFormat = '') {
    return moment(date, dateFormat)
  }

  getYesterday() {
    return moment().subtract(1, 'day')
  }

  format(date, format = 'DD/MM/YY', dateFormat = '') {
    return moment(date, dateFormat).format(format)
  }

  isValid(date, format = 'DD/MM/YYYY') {
    return moment(date, format).isValid()
  }

  isSameOrAfter(start, end, format = 'YYYY-MM-DD') {
    return moment(end, format).isSameOrAfter(moment(start, format))
  }

  utcToLocal(datetime, format = 'HH:mm') {
    const utc = moment(datetime, 'HH:mm:ss').utc()
    return moment(utc).local().format(format)
  }

  formatUTC(date, format = 'HH:mm', dateFormat = 'HH:mm:ss') {
    return moment.utc(date, dateFormat).local().format(format)
  }

  formatToUTC(date, format = 'DD/MM/YY', dateFormat = '') {
    return moment(date, dateFormat).utc().format(format)
  }

  timeUntilEnd(end) {
    return moment.duration(moment(moment(end).diff(moment(moment.utc().toISOString()))).format('HH:mm'), 'HH:mm').humanize(true)
  }

  durationUntilEnd(end) {
    return moment(moment(end).diff(moment(moment.utc().toISOString()))).format('HH:mm:ss')
  }

  isAfterNow(date) {
    return moment.utc(date).isSameOrAfter(moment.utc())
  }

  yearOptions(range = 10) {
    let date = moment()
    const years = []
    let year = 0

    while (year <= range) {
      years.push({ value: date.format('YYYY'), label: date.format('YYYY') })
      date = date.clone().add(1, 'y')
      year += 1
    }
    return years
  }
}

const dateUtils = new DateUtils();
export default dateUtils;
