import initialState from "./initialState";
import {
  REQUEST_CHANGE_USER_PASS,
  REQUEST_CHANGE_USER_PASS_SUCCESS,
  REQUEST_CHANGE_USER_PASS_ERROR,
  HANDLE_CHANGE_USER_PASS_FORM_VALUE_CHANGE,
  CLEAR_CHANGE_USER_PASS_FORM,
  REQUEST_FORGOT_PASSWORD,
} from "../Actions/actionTypes";

const requestChangeUserPass = (state, action) => {
  return { ...state, isSaving: true };
};

const requestChangeUserPassSuccess = (state, action) => {
  return { ...initialState.userPreRegisterForm, isSaveComplete: true };
};

const requestChangeUserPassError = (state, action) => {
  return { ...state, isSaving: false, ...action.payload };
};

const handleValueChange = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const clearChangeUserPassForm = (state, action) => {
  return initialState.userChangePassForm;
};

const requestForgotPassword = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const actions = {
  [REQUEST_CHANGE_USER_PASS]: requestChangeUserPass,
  [REQUEST_CHANGE_USER_PASS_SUCCESS]: requestChangeUserPassSuccess,
  [REQUEST_CHANGE_USER_PASS_ERROR]: requestChangeUserPassError,
  [HANDLE_CHANGE_USER_PASS_FORM_VALUE_CHANGE]: clearChangeUserPassForm,
  [CLEAR_CHANGE_USER_PASS_FORM]: handleValueChange,
  [REQUEST_FORGOT_PASSWORD]: requestForgotPassword,
};

export const userChangePassForm = (
  state = initialState.userChangePassForm,
  action
) => {
  return actions[action.type] ? actions[action.type](state, action) : state;
};

export const userForgotPassForm = (state = initialState, action) => {
  return actions[action.type] ? actions[action.type](state, action) : state;
};
