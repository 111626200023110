import React from 'react'
import { ClosedSignImg } from '../../Assets'
import { dateUtils } from '../../Utils'

export default function ClosedSign(props) {
    return (
        <div className='closed-sign-container'>
            <div className='img'>
                <img src={ClosedSignImg} alt='Placa de fechado' />
            </div>
            <div className='title'>
                <span>OFERTAS DO DIA FINALIZADAS!</span>
            </div>
            <div className='msg'>
                <span>Retorne entre {dateUtils.formatUTC(props.exchange.opensOn, 'HH:mm', 'HH:mm:ss')} e {dateUtils.formatUTC(props.exchange.closesOn, 'HH:mm', 'HH:mm:ss')} <br /> para {props.message}.</span>
            </div>
        </div>
    )
}
