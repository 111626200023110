import React, { Component } from "react";
import Select from "react-select";
import { alertService } from "../../Services";

class CustomSelect extends Component {
  render() {
    const onChange = (e) => {
      let value;
      if (e) {
        if (this.props.isMulti) {
          if (e.length > this.props.maxValues) {
            value = this.props.value;
            alertService.info(
              `Máximo de ${this.props.maxValues} ${this.props.optionsName}!`
            );
          } else value = e;
        } else {
          value = e.value;
        }
      }

      const event = {
        target: {
          name: this.props.name,
          value,
        },
      };
      this.props.onChange(event);
    };

    const customTheme = (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: "rgb(245, 133, 32)",
        primary75: "rgb(245, 133, 32, 0.75)",
        primary50: "rgb(245, 133, 32, 0.50)",
        primary25: "rgb(245, 133, 32, 0.25)",
      },
    });

    const customStyles = {
      option: (provided) => ({
        ...provided,
        padding: 10,
        color: "#585C5C",
        fontWeight: "bold",
      }),
      control: (styles, { isFocused }) => ({
        ...styles,
        minWidth: "200px",
        height: "40px",
        fontSize: "14px",
        backgroundColor: "#585C5C",
        cursor: "pointer",
        border: "none",
        boxShadow: isFocused ? "0 0 0 0.2rem rgb(245, 133, 32)" : "none",
      }),
      singleValue: (styles) => ({
        ...styles,
        color: "white",
      }),
      placeholder: (defaultPlaceHolder) => ({
        ...defaultPlaceHolder,
        color: "white",
      }),
      input: (inputDefault) => ({
        ...inputDefault,
        color: "white",
      }),
      valueContainer: (valueContainerDefault) => ({
        ...valueContainerDefault,
      }),
    };

    const optionValue =
      this.props.options.find((option) => option.value === this.props.value) ||
      null;
    const value = this.props.isMulti ? this.props.value : optionValue;

    return (
      <Select
        id={this.props.id}
        value={value}
        name={this.props.name}
        options={this.props.options}
        placeholder={this.props.placeholder || ""}
        onChange={onChange}
        isSearchable={this.props.isSearchable}
        defaultValue={this.props.defaultValue}
        className={this.props.className}
        classNamePrefix={this.props.classNamePrefix}
        isDisabled={this.props.isDisabled}
        autoFocus={this.props.autoFocus}
        styles={customStyles}
        theme={customTheme}
        isMulti={this.props.isMulti}
        maxValues={this.props.maxValues}
        isClearable={this.props.isClearable}
        isLoading={this.props.isLoading}
        noOptionsMessage={() =>
          this.props.noOptionsMessage || "Não há opções disponiveis"
        }
        optionsName={this.props.optionsName}
      ></Select>
    );
  }
}

CustomSelect.defaultProps = {
  options: [],
  isSearchable: true,
  isMulti: false,
  isClearable: false,
  isLoading: false,
};

export default CustomSelect;
