import React from "react";
import { CheckboxInput } from "../../Components";
import { useForm } from "../../Hooks";
import { userDataStorageUtils } from "../../Utils";
import { useModal } from "../../Hooks";
import { Modal, ForgotPasswordModal } from "../../Components";

const LoginForm = ({ handleLogin }) => {
  const keepConnected = userDataStorageUtils.getKeepConnected()
    ? userDataStorageUtils.getKeepConnected()
    : false;
  const { values, handleChange, handleSubmit } = useForm(
    { email: "", password: "", keepConnected },
    () => handleLogin(values)
  );
  const {
    isShowing: isResetPasswordModalShowing,
    toggle: togglePasswordResetModal,
  } = useModal();

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <label>E-MAIL</label>
        <input
          name="email"
          placeholder=""
          value={values.email}
          onChange={handleChange}
          className="mb-5 login-input"
          autoFocus
        />
        <label>SENHA</label>
        <input
          name="password"
          type="password"
          placeholder=""
          value={values.password}
          onChange={handleChange}
          className="login-input"
        />
        <div className="forgot-password text-white mt-2 text-center">
          <span onClick={() => togglePasswordResetModal()}>
            Esqueceu sua senha?
          </span>
        </div>
        <CheckboxInput
          label="Manter conectado"
          name="keepConnected"
          value={values.keepConnected}
          onToggle={(e) => handleChange(e)}
        />
        <div className="d-flex flex-row-reverse">
          <button className="btn login-btn" type="submit">
            Entrar
          </button>
        </div>
      </form>
      <Modal
        isShowing={isResetPasswordModalShowing}
        hide={togglePasswordResetModal}
        isLoading={false}
        content={<ForgotPasswordModal hide={togglePasswordResetModal} />}
      />
    </>
  );
};

export default LoginForm;
