import BaseService from './base.service'

class CounterOfferService extends BaseService {
  constructor() {
    super('CounterOffer');
  }

  confirmCounterOffer(id) {
    return this.post({}, `/${id}/Accept`)
  }

  refuseCounterOffer(id) {
    return this.post({}, `/${id}/Reject`)
  }
}

export default CounterOfferService;
