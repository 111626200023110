import BaseService from './base.service'

class PriceIndicationService extends BaseService {
  constructor() {
    super('Price');
  }

  getUnpublished(options) {
    return this.post(options, '/FindAllTodayNotPublished')
  }

  getPublished(options = {}) {
    return this.post(options, '/FindAllTodayPublished')
  }

  deletePrices(ids) {
    return this.deleteList(ids, '/List')
  }

  publishPrices(ids) {
    return this.post(ids, '/PublishList')
  }

  updateValue(id, priceData) {
    return this.post(priceData, `/${id}/UpdateValue`)
  }

  calculateReward(priceInfo) {
    return this.post(priceInfo, '/CalculateReward')
  }
}

export default PriceIndicationService;
