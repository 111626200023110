import React from "react";
import { useModal } from "../../Hooks";
import { Modal, ContactModal } from "../../Components";

export default function Footer() {
  const { isShowing: isContactModalShowing, toggle: toggleContactModal } =
    useModal();

  return (
    <div className="footer-background-container">
      <div className="footer-container">
        <div className="footer-top-container">
          <i className="ic-logo-orange"></i>
          <div className="footer-nav">
            <a href="#/">Indicativos de Preços</a>
            <a href="#/offer">Criar uma Oferta</a>
            <a href="#/myNegotiations">Minhas Negociações</a>
            <span onClick={() => toggleContactModal()}>Contato</span>
          </div>
        </div>

        <div className="footer-bottom-container">
          <span>
            {new Date().getFullYear()} -Inova Grãos Comodities Agrícolas
          </span>
        </div>
        <Modal
          isShowing={isContactModalShowing}
          hide={toggleContactModal}
          isLoading={false}
          content={<ContactModal />}
        />
      </div>
    </div>
  );
}
