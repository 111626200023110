import * as types from "../actionTypes";
import { PriceIndicationService, alertService } from "../../Services";
import { dateUtils } from "../../Utils";
import { signalRActions } from "..";

const priceService = new PriceIndicationService();

export const handleValueChange = (edition) => ({
  type: types.HANDLE_PRICES_INDICATIONS_VALUE_CHANGE,
  payload: edition,
});

export const requestPublishedPrices = () => ({
  type: types.REQUEST_PUBLISHED_PRICES,
  payload: null,
});

export const requestPublishedPricesSuccess = (publishedPrices) => ({
  type: types.REQUEST_PUBLISHED_PRICES_SUCCESS,
  payload: publishedPrices,
});

export const requestPublishedPricesError = (error) => ({
  type: types.REQUEST_PUBLISHED_PRICES_ERROR,
  payload: error,
});

export const fetchPublishedPrices = (options, subscribeData) => (dispatch) => {
  dispatch(requestPublishedPrices());
  dispatch(signalRActions.unsubscribeAllCotationsAndExchanges());
  priceService
    .getPublished(options)
    .then((response) => {
      if (response.data) {
        const publishedPrices = response.data.map((price) => {
          return {
            ...price,
            updatedCotation: price.cotation,
            updatedExchangeFee: price.exchangeFee,
            updatedValue: price.value,
            paymentOn: dateUtils.format(price.paymentOn),
            deliveryStartsOn: dateUtils.format(price.deliveryStartsOn),
            deliveryEndsOn: dateUtils.format(price.deliveryEndsOn),
          };
        });
        if (
          subscribeData.isPriceConnected &&
          (!subscribeData.indicationsCotationSubscribed ||
            !subscribeData.indicationsExchangeSubscribed)
        ) {
          dispatch(
            signalRActions.subscribeCotations(
              publishedPrices,
              subscribeData.cotationControl
            )
          );
          dispatch(
            signalRActions.subscribeExchanges(
              publishedPrices,
              subscribeData.exchangeControl
            )
          );
        }
        dispatch(requestPublishedPricesSuccess(publishedPrices));
      }
    })
    .catch((error) => {
      dispatch(requestPublishedPricesError(error));

      alertService.error(error, "Erro ao buscar indicações de preços");
    });
};

export const requestUnpublishPrice = () => ({
  type: types.REQUEST_UNPUBLISH_PRICE,
  payload: null,
});

export const requestUnpublishPriceSuccess = () => ({
  type: types.REQUEST_UNPUBLISH_PRICE_SUCCESS,
  payload: null,
});

export const requestUnpublishPriceError = (error) => ({
  type: types.REQUEST_UNPUBLISH_PRICE_ERROR,
  payload: error,
});

export const unpublish = (id, hideModal, options) => (dispatch) => {
  dispatch(requestUnpublishPrice());
  priceService
    .delete(id)
    .then(() => {
      dispatch(requestUnpublishPriceSuccess());
      alertService.success("Preço excluído com sucesso!");
      dispatch(fetchPublishedPrices(options));
      hideModal();
    })
    .catch((error) => {
      dispatch(requestUnpublishPriceError(error));
      alertService.error(error, "Erro ao excluir preço");
    });
};

export const requestUpdatePriceValue = () => ({
  type: types.REQUEST_UPDATE_PRICE_VALUE,
  payload: null,
});

export const requestUpdatePriceValueSuccess = () => ({
  type: types.REQUEST_UPDATE_PRICE_VALUE_SUCCESS,
  payload: null,
});

export const requestUpdatePriceValueError = (error) => ({
  type: types.REQUEST_UPDATE_PRICE_VALUE_ERROR,
  payload: error,
});

export const updateValue =
  (id, priceData, hideModal, options) => (dispatch) => {
    dispatch(requestUpdatePriceValue());
    priceService
      .updateValue(id, { ...priceData })
      .then(() => {
        dispatch(requestUpdatePriceValueSuccess());
        alertService.success("Preço atualizado com sucesso!");
        dispatch(fetchPublishedPrices(options));
        hideModal();
      })
      .catch((error) => {
        dispatch(requestUpdatePriceValueError(error));
        alertService.error(error, "Erro ao atualizar preço");
      });
  };

export const cotationUpdate = (cbotId, cotation) => ({
  type: types.PRICE_INDICATIONS_COTATION_UPDATE,
  payload: { cbotId, cotation },
});

export const exchangeUpdate = (date, exchange) => ({
  type: types.PRICE_INDICATIONS_EXCHANGE_UPDATE,
  payload: { date, exchange },
});
