import initialState from '../initialState'
import {
    REQUEST_ACCEPT_COUNTER_OFFER,
    REQUEST_ACCEPT_COUNTER_OFFER_SUCCESS,
    REQUEST_ACCEPT_COUNTER_OFFER_ERROR,
    REQUEST_REFUSE_COUNTER_OFFER,
    REQUEST_REFUSE_COUNTER_OFFER_SUCCESS,
    REQUEST_REFUSE_COUNTER_OFFER_ERROR
} from '../../Actions/actionTypes'

const requestAcceptCounterOffer = (state, action) => {
    return { ...state, isSaving: true }
}
const requestAcceptCounterOfferSuccess = (state, action) => {
    return { ...state, isSaving: false }
}
const requestAcceptCounterOfferError = (state, action) => {
    return { ...state, isSaving: false }
}
const requestRefuseCounterOffer = (state, action) => {
    return { ...state, isSaving: true }
}
const requestRefuseCounterOfferSuccess = (state, action) => {
    return { ...state, isSaving: false }
}
const requestRefuseCounterOfferError = (state, action) => {
    return { ...state, isSaving: false }
}

const actions = {
    [REQUEST_ACCEPT_COUNTER_OFFER]: requestAcceptCounterOffer,
    [REQUEST_ACCEPT_COUNTER_OFFER_SUCCESS]: requestAcceptCounterOfferSuccess,
    [REQUEST_ACCEPT_COUNTER_OFFER_ERROR]: requestAcceptCounterOfferError,
    [REQUEST_REFUSE_COUNTER_OFFER]: requestRefuseCounterOffer,
    [REQUEST_REFUSE_COUNTER_OFFER_SUCCESS]: requestRefuseCounterOfferSuccess,
    [REQUEST_REFUSE_COUNTER_OFFER_ERROR]: requestRefuseCounterOfferError,
};

export const counterOffers = (state = initialState.counterOffers, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
