import React, { Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Loader from 'react-loader'
import Header from './header'
import Footer from './footer'
import { AuthService, Routes } from '../../Services'
import { signalRActions, notificationActions } from '../../Actions'
import { Redirect } from 'react-router-dom'

export default function Home() {
  const dispatch = useDispatch()
  const signalR = useSelector(state => state.signalR)
  const notifications = useSelector(state => state.notifications)

  useEffect(() => { if (!signalR.isConnected) dispatch(signalRActions.requestConnection()) }, [dispatch, signalR.isConnected])
  useEffect(() => { return () => dispatch(signalRActions.closeConnection()) }, [dispatch])

  useEffect(() => { if (notifications.entities.length === 0) dispatch(notificationActions.fetchNotifications()) }, [dispatch, notifications.entities.length])

  const loading = () => <Loader color='#FFFF' loaded={false} />
  const authService = new AuthService()

  const readHandler = id => {
    dispatch(notificationActions.readHandler(id))
  }

  return authService.isAuthenticated ?
    (
      <div className='home-page'>
        <Header notifications={notifications.entities} onNotificationRead={readHandler} />
        <div className='home-body'>
          <Suspense fallback={loading()}>
            <Switch >
              {Routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <route.component {...props} />
                    )} />
                ) : null;
              })}
            </Switch>
          </Suspense>
        </div>
        <Footer />
      </div>
    ) : <Redirect to='/login' />
}
