import React from "react";
import { PaperPlane } from "../../Assets";

export default function ContactModal() {
  return (
    <div className="contact-modal">
      <div className="img-section">
        <img src={PaperPlane} alt="Avião de Papel" />
      </div>
      <div className="title-container">
        <span>
          Em caso de dúvida
          <br />
          entre em contato conosco:
        </span>
      </div>
      <div className="mail-section">
        <span>E-mail:</span>
        <a href="mailto:comercial@inovagraos.com.br">
          comercial@inovagraos.com.br
        </a>
      </div>
      <div className="mail-section">
        <span>Celular:</span>
        <span className="phone">(51) 98051-0017</span>
      </div>
    </div>
  );
}
