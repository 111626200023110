import React from "react";
import { RadioInputCard } from "../../Components";
import { useForm } from "../../Hooks";
import NumberFormat from "react-number-format";
import Loader from "react-loader";
import { redirectUtils } from "../../Utils";

export default function UserPreRegisterForm({
  onSavePreRegister,
  activityTypeOptions,
  isSaving,
  fields,
}) {
  const { values, handleChange, handleSubmit, clear } = useForm(
    { ...fields },
    () => onSavePreRegister({ ...values }, clear)
  );

  return (
    <div className="register-form-container">
      <Loader color="#fff" loaded={!isSaving}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="d-flex flex-wrap">
            <div className="form-section">
              <div className="form-row">
                <label>Nome</label>
                <input
                  className="inova-input"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  autoFocus
                />
              </div>
              <div className="form-row">
                <div className="cpf-row">
                  <label>CPF</label>
                  <NumberFormat
                    className="inova-input"
                    mask="_"
                    format="###.###.###-##"
                    value={values.cpf}
                    onValueChange={(e) =>
                      handleChange({ target: { name: "cpf", value: e.value } })
                    }
                  />
                </div>
                <div>
                  <label>Data Nasc.</label>
                  <NumberFormat
                    className="inova-input"
                    placeholder="DD/MM/YYYY"
                    mask="_"
                    format="##/##/####"
                    value={values.bornOn}
                    onValueChange={(e) =>
                      handleChange({
                        target: { name: "bornOn", value: e.formattedValue },
                      })
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <label>Fone</label>
                <NumberFormat
                  className="inova-input"
                  format="(##) ##### ####"
                  value={values.phoneNumber}
                  onValueChange={(e) =>
                    handleChange({
                      target: { name: "phoneNumber", value: e.value },
                    })
                  }
                />
              </div>
              <div className="form-row">
                <label>E-mail</label>
                <input
                  className="inova-input"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-section">
              <div className="form-row">
                <label>Nome da empresa</label>
                <input
                  className="inova-input"
                  name="clientName"
                  value={values.clientName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-row">
                <label>CNPJ</label>
                <NumberFormat
                  className="inova-input"
                  mask="_"
                  format="##.###.###/####-##"
                  value={values.clientCPFCNPJ}
                  onValueChange={(e) =>
                    handleChange({
                      target: { name: "clientCPFCNPJ", value: e.value },
                    })
                  }
                />
              </div>
              <div className="form-row">
                <label>Ramo da atividade</label>
                <div className="cards-container">
                  {activityTypeOptions.map((ato) => (
                    <RadioInputCard
                      name="clientActivityType"
                      key={ato.value}
                      label={ato.label}
                      value={ato.value}
                      onChange={handleChange}
                      checked={values.clientActivityType === ato.value}
                      iconClass={ato.iconClass}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row-reverse">
            <button className="btn inova-btn primary ml-3">Enviar</button>
            <button
              className="btn inova-btn secondary"
              type="button"
              onClick={() => redirectUtils.redirectTo("#/")}
            >
              Cancelar
            </button>
          </div>
        </form>
      </Loader>
    </div>
  );
}
