import * as types from '../actionTypes'
import { OfferService, alertService, TradeService } from '../../Services'
import { dateUtils, redirectUtils } from '../../Utils';
import { signalRActions } from '..';

const tradeService = new TradeService();
const offerService = new OfferService();

export const requestTodayOffers = () => ({
    type: types.REQUEST_TODAY_OFFERS,
    payload: null
})

export const requestTodayOffersSuccess = offers => ({
    type: types.REQUEST_TODAY_OFFERS_SUCCESS,
    payload: offers
})

export const requestTodayOffersError = error => ({
    type: types.REQUEST_TODAY_OFFERS_ERROR,
    payload: error
})

export const fetchTodayOffers = options => dispatch => {
    dispatch(requestTodayOffers());
    offerService.findAllActiveForCurrentUser(options)
        .then(response => {
            if (response.data) {
                const todayOffers = response.data.map(offer => {
                    const price = offer.price ? {
                        ...offer.price,
                        paymentOn: dateUtils.format(offer.price.paymentOn),
                        updatedValue: offer.price.updatedValue ? offer.price.updatedValue : offer.price.value,
                        updatedExchangeFee: offer.price.exchangeFee,
                        updatedCotation: offer.price.updatedCotation ? offer.price.updatedCotation : offer.price.cotation
                    } : null

                    return {
                        ...offer,
                        paymentOn: dateUtils.format(offer.paymentOn),
                        deliveryStartsOn: dateUtils.format(offer.deliveryStartsOn),
                        deliveryEndsOn: dateUtils.format(offer.deliveryEndsOn),
                        price
                    }

                })
                dispatch(requestTodayOffersSuccess(todayOffers.filter(offer => dateUtils.isAfterNow(offer.expiresOn) && offer.state !== 3)));
                dispatch(signalRActions.handleValueChange({ todayOffersCotationSubscribed: false, todayOffersExchangeSubscribed: false }))
            }
        }).catch(error => {
            dispatch(requestTodayOffersError(error));
            alertService.error(error, 'Erro ao buscar ofertas do dia');
        })
}

export const requestOffer = () => ({
    type: types.REQUEST_OFFER,
    payload: null
});

export const requestOfferSuccess = offer => ({
    type: types.REQUEST_OFFER_SUCCESS,
    payload: offer
})

export const requestOfferError = error => ({
    type: types.REQUEST_OFFER_ERROR,
    payload: error
});

export const fetchOffer = id => dispatch => {
    dispatch(requestOffer());
    offerService.getById(id)
        .then(res => {
            if (res.data) {
                const offer = {
                    ...res.data,
                    paymentOn: dateUtils.format(res.data.paymentOn),
                    deliveryStartsOn: dateUtils.format(res.data.deliveryStartsOn),
                    deliveryEndsOn: dateUtils.format(res.data.deliveryEndsOn),
                    counterOffer: res.data.counterOffer && {
                        ...res.data.counterOffer,
                        paymentOn: res.data.counterOffer.paymentOn ? dateUtils.format(res.data.counterOffer.paymentOn) : null,
                        deliveryStartsOn: res.data.counterOffer.deliveryStartsOn ? dateUtils.format(res.data.counterOffer.deliveryStartsOn) : null,
                        deliveryEndsOn: res.data.counterOffer.deliveryEndsOn ? dateUtils.format(res.data.counterOffer.deliveryEndsOn) : null,
                    }
                }
                dispatch(requestOfferSuccess(offer))
            }
        }).catch(error => {
            dispatch(requestOfferError(error))
            redirectUtils.redirectTo('#/myNegotiations')
            alertService.error(error, 'Erro ao buscar oferta')
        })
};

export const requestCancelOffer = () => ({
    type: types.REQUEST_CANCEL_OFFER,
    payload: null
});

export const requestCancelOfferSuccess = res => ({
    type: types.REQUEST_CANCEL_OFFER_SUCCESS,
    payload: res
})

export const requestCancelOfferError = error => ({
    type: types.REQUEST_CANCEL_OFFER_ERROR,
    payload: error
});

export const cancelOffer = (id, closeModal) => dispatch => {
    dispatch(requestCancelOffer());
    offerService.cancelOffer(id)
        .then(res => {
            dispatch(requestCancelOfferSuccess(res.data))
            closeModal()
            alertService.success('Oferta cancelada com sucesso!')
        }
        ).catch(error => {
            dispatch(requestCancelOfferError(error))
            alertService.error(error, 'Erro ao cancelar oferta')
        })
};

export const cotationUpdate = (cbotId, cotation) => ({
    type: types.OFFERS_COTATION_UPDATE,
    payload: { cbotId, cotation }
})

export const exchangeUpdate = (date, exchange) => ({
    type: types.OFFERS_EXCHANGE_UPDATE,
    payload: { date, exchange }
})

export const requestContractFile = () => ({
    type: types.REQUEST_NEGOTIATION_CONTRACT,
    payload: null
});
export const requestContractFileSuccess = contract => ({
    type: types.REQUEST_NEGOTIATION_CONTRACT_SUCCESS,
    payload: contract
})
export const requestContractFileError = error => ({
    type: types.REQUEST_NEGOTIATION_CONTRACT_ERROR,
    payload: error
});
export const fetchContract = (contractURL, fileName) => dispatch => {
    dispatch(requestContractFile());

    tradeService.getNegotiationContract(contractURL)
        .then(response => {
            if (response.data) {
                var blob = new Blob([response.data], { type: 'application/pdf' })
                const url = window.URL.createObjectURL(blob);
                var win = window.open(url, '_self');
                win.focus();

                dispatch(requestContractFileSuccess());
            }
        })
        .catch(error => {
            alertService.error({ ...error, response: error.response || 'CORS' }, 'Erro ao abrir contrato');
            dispatch(requestContractFileError(error));
        });
};

export const handleValueChange = edition => ({
    type: types.HANDLE_OFFER_VALUE_CHANGE,
    payload: edition
});
