import * as types from "./actionTypes";
import { AuthService, alertService, TermsService } from "../Services";
import { userDataStorageUtils, redirectUtils } from "../Utils";
import axios from "axios";
import config from "../Services/config";

const authService = new AuthService();
const termsService = new TermsService();

export const handleChangeValue = (value) => ({
  type: types.HANDLE_AUX_CHANGE_VALUE,
  payload: value,
});

export const requestAuth = () => ({
  type: types.REQUEST_AUTH,
  payload: null,
});

export const requestAuthError = (error) => ({
  type: types.REQUEST_AUTH_ERROR,
  payload: error,
});

export const setUserData = (user) => ({
  type: types.SET_USER_DATA,
  payload: user,
});

export const requestLogin = (data) => (dispatch) => {
  dispatch(requestAuth());
  authService
    .post(data, "user")
    .then((res) => {
      const user = res.data.user;
      dispatch(
        setUserData({
          ...user,
          keepConnected: data.keepConnected,
          token: res.data.token,
        })
      );
      userDataStorageUtils.setUserData({ id: user.id, name: user.name });
      if (user.passwordTemporary || !user.termsOfServiceAllowed) {
        redirectUtils.redirectTo("#/firstAccess");
      } else {
        userDataStorageUtils.setAuthInfo({
          token: res.data.token,
          keepConnected: data.keepConnected,
        });
        redirectUtils.redirectTo("#/");
      }
    })
    .catch((err) => {
      dispatch(requestAuthError(err));
      alertService.error(
        err,
        "Falha ao logar! Verifique seu email e/ou senha!"
      );
    });
};

export const requestChangeTempPass = () => ({
  type: types.REQUEST_CHANGE_TEMP_PASS,
  payload: null,
});

export const requestChangeTempPassError = (error) => ({
  type: types.REQUEST_CHANGE_TEMP_PASS_ERROR,
  payload: error,
});

export const requestChangeTempPassSuccess = (data) => ({
  type: types.REQUEST_CHANGE_TEMP_PASS_SUCCESS,
  payload: data,
});

export const requestFirstAccess = (data) => (dispatch) => {
  dispatch(requestChangeTempPass());
  const instance = createTempAxiosInstance(data.token);

  instance
    .post("/User/ChangeTempPassword", { newPassword: data.password })
    .then((res) => {
      dispatch(requestChangeTempPassSuccess());
      redirectUtils.redirectTo("#/");
    })
    .catch((err) => {
      dispatch(requestChangeTempPassError(err));
      alertService.error(err, "Falha ao alterar senha!");
    });
};

export const requestAllowTermsOfService = () => ({
  type: types.REQUEST_ALLOW_TERMS_OF_SERVICE,
  payload: null,
});

export const requestAllowTermsOfServiceError = (error) => ({
  type: types.REQUEST_ALLOW_TERMS_OF_SERVICE_ERROR,
  payload: error,
});

export const requestAllowTermsOfServiceSuccess = (data) => ({
  type: types.REQUEST_ALLOW_TERMS_OF_SERVICE_SUCCESS,
  payload: data,
});

export const requestAcceptanceTermsOfService = (data) => (dispatch) => {
  dispatch(requestAllowTermsOfService());
  const instance = createTempAxiosInstance(data.token);

  instance
    .post("/User/AllowTermsOfService")
    .then((res) => {
      dispatch(requestAllowTermsOfServiceSuccess(res.data));
      userDataStorageUtils.setAuthInfo({
        token: data.token,
        keepConnected: data.keepConnected,
      });
      redirectUtils.redirectTo("#/firstAccess");
    })
    .catch((err) => {
      dispatch(requestAllowTermsOfServiceError(err));
      alertService.error(err, "Falha ao aceitar os termos de uss!");
    });
};

const createTempAxiosInstance = (token) => {
  const instance = axios.create({
    baseURL: config.backURL,
  });

  instance.interceptors.request.use((config) => {
    config.headers.common["Authorization"] = `Bearer ${token}`;
    return config;
  });

  return instance;
};

export const requestTermsOfService = () => ({
  type: types.REQUEST_TERMS_OF_SERVICE,
  payload: null,
});

export const requestTermsOfServiceError = (error) => ({
  type: types.REQUEST_TERMS_OF_SERVICE_ERROR,
  payload: error,
});

export const requestTermsOfServiceSuccess = (data) => ({
  type: types.REQUEST_TERMS_OF_SERVICE_SUCCESS,
  payload: data,
});

export const fetchTermsOfService = (data) => (dispatch) => {
  dispatch(requestTermsOfService());

  const instance = createTempAxiosInstance(data.token);

  instance
    .get("/TermsOfService")
    .then((res) => {
      if (res.data) dispatch(requestTermsOfServiceSuccess(res.data.text));
    })
    .catch((err) => {
      dispatch(requestTermsOfServiceError(err));
    });
};

export const fetchTermsOpen = () => (dispatch) => {
  dispatch(requestTermsOfService());
  termsService
    .get({})
    .then((res) => {
      if (res.data) dispatch(requestTermsOfServiceSuccess(res.data.text));
    })
    .catch((err) => {
      dispatch(requestTermsOfServiceError(err));
    });
};
