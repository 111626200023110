// Images
import logo from "./Img/LOGO_white.svg";
import logo_orange from "./Img/LOGO_orange.svg";
import user from "./Img/user.svg";
import SVGIcon from "./SVGIcon";
import MagnifyingGlass from "./Img/ic-lupa.svg";
import ClosedSignImg from "./Img/closed-sign.svg";
import PaperPlane from "./Img/paper-plane.svg";

export {
  logo,
  logo_orange,
  user,
  SVGIcon,
  MagnifyingGlass,
  ClosedSignImg,
  PaperPlane,
};
