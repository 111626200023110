import { ShippingAgreementTypeService, alertService } from '../Services'
import * as types from './actionTypes'

const shippingAgreementTypeService = new ShippingAgreementTypeService();

export const requestShippingAgreementTypes = () => ({
    type: types.REQUEST_SHIPPING_AGREEMENT_TYPES,
    payload: null
});

export const requestShippingAgreementTypesSuccess = shippingAgreementTypes => ({
    type: types.REQUEST_SHIPPING_AGREEMENT_TYPES_SUCCESS,
    payload: shippingAgreementTypes
});

export const requestShippingAgreementTypesError = error => ({
    type: types.REQUEST_SHIPPING_AGREEMENT_TYPES_ERROR,
    payload: error
});

export const fetchShippingAgreementTypes = () => dispatch => {
    dispatch(requestShippingAgreementTypes());
    shippingAgreementTypeService
        .findAll()
        .then(response => {
            if (response.data) {
                const shippingAgreementTypes = response.data;
                const shippingAgreementTypesOptions = shippingAgreementTypes.map(shippingAgreementType => {
                    const label = shippingAgreementType.code === 'FOB' ? 'FOB' : shippingAgreementType.description
                    return {
                        value: shippingAgreementType.id,
                        label,
                        ...shippingAgreementType
                    };
                });

                dispatch(requestShippingAgreementTypesSuccess(shippingAgreementTypesOptions));
            }
        })
        .catch(error => {
            dispatch(requestShippingAgreementTypesError(error));
            alertService.error(error, 'Erro ao buscar ramo de atividades');
        });
};