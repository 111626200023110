import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import Home from '../Home/home.page';

// Import routing components
import { Switch, Route, HashRouter } from 'react-router-dom';
import { PrivateRoute } from '../../Components';
import LoginContainer from '../Login/login.container';
import UserPreRegisterContainer from '../UserPreRegister/userPreRegister.container';
import FirstAccessContainer from '../Login/firstAccess.container'
import TermsOpenContainer from '../Terms/terms.open.container';

export default class Root extends Component {
  render() {
    return (
      <>
        <HashRouter>
          <Switch>
            <Route path='/login' name='Login Page' component={LoginContainer} />
            <Route path='/register' name='Register Page' component={UserPreRegisterContainer} />
            <Route path='/firstAccess' name='First Access Page' component={FirstAccessContainer} />
            <Route path='/termos' name='Termos de Uso' component={TermsOpenContainer} />
            <PrivateRoute
              path='/'
              name='Home Page'
              component={Home}
            />
          </Switch>
        </HashRouter>
        <ToastContainer
          newestOnTop
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          pauseOnVisibilityChange
        />
      </>
    );
  }
}
