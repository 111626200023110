import BaseService from './base.service'

class HarvestService extends BaseService {
  constructor() {
    super('Harvest');
  }

  findAllGroupedByProduct() {
    return this.post({}, '/FindAllGroupedByProductId')
  }
}

export default HarvestService;
