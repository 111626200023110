import BaseService from './base.service'

class OfferService extends BaseService {
  constructor() {
    super('Offer');
  }

  findAllActiveForCurrentUser(options = {}) {
    return this.post(options, '/FindAllActiveForCurrentUser')
  }

  findAllHistoryForCurrentUser(options = {}) {
    return this.post(options, '/FindAllHistoryForCurrentUser')
  }

  cancelOffer(id) {
    return this.delete(id, '/Cancel')
  }
}

export default OfferService;
