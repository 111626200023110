import React from 'react'

export default function RadioInputBtn(props) {

    const clickHandler = () => {
        const event = { target: { name: props.name, value: props.value } }
        props.onChange(event)
    }

    return (
        <div className='radio-input' onClick={() => clickHandler()}>
            <input
                id={props.id}
                type='radio'
                disabled={props.disabled}
                checked={props.checked}
                required={props.required}
                name={props.name}
                value={props.value}
                onChange={() => { }}
            />

            <div className='label-container'>
                <i className='fa fa-check' />
                <label htmlFor={props.id}>
                    {props.label}
                </label>
            </div>
        </div>
    )
}
