// DEV: https://app-agrobrick-dev-api.azurewebsites.net
// PROD: https://app-agrobrick-prod-api.azurewebsites.net
const BACKEND_BASE_URL = (env) =>
  `https://app-agrobrick-${env}-api.azurewebsites.net`;
const FRONT_URL = "http://localhost:3000";
let backURL = "";
let signalRURL = "";
let notificationHubURL = "";

function setBackURL() {
  if (process.env.NODE_ENV === "development") {
    backURL = BACKEND_BASE_URL("prod");
  }

  if (process.env.NODE_ENV === "production") {
    backURL = BACKEND_BASE_URL("prod");
  }

  return `${backURL}/api`;
}

function setSignalRURL() {
  if (process.env.NODE_ENV === "production") {
    signalRURL =
      "https://app-agrobrick-prod-api.azurewebsites.net/inovaPriceHub";
  }

  if (process.env.NODE_ENV === "development") {
    signalRURL =
      "https://app-agrobrick-prod-api.azurewebsites.net/inovaPriceHub";
  }

  return signalRURL;
}

function setNotificationHubURL() {
  if (process.env.NODE_ENV === "production") {
    notificationHubURL =
      "https://app-agrobrick-prod-api.azurewebsites.net/inovaNotificationUpdateHub";
  }

  if (process.env.NODE_ENV === "development") {
    notificationHubURL =
      "https://app-agrobrick-prod-api.azurewebsites.net/inovaNotificationUpdateHub";
  }

  return notificationHubURL;
}

const config = {
  backURL: setBackURL(),
  frontURL: FRONT_URL,
  signalRURL: setSignalRURL(),
  notificationHubURL: setNotificationHubURL(),
};

export default config;
