import initialState from './initialState';
import {
    REQUEST_AUTH, REQUEST_AUTH_ERROR, SET_USER_DATA,
    REQUEST_CHANGE_TEMP_PASS,
    REQUEST_CHANGE_TEMP_PASS_ERROR,
    REQUEST_CHANGE_TEMP_PASS_SUCCESS,
    REQUEST_ALLOW_TERMS_OF_SERVICE,
    REQUEST_ALLOW_TERMS_OF_SERVICE_SUCCESS,
    REQUEST_ALLOW_TERMS_OF_SERVICE_ERROR,
    HANDLE_AUX_CHANGE_VALUE,
    REQUEST_TERMS_OF_SERVICE,
    REQUEST_TERMS_OF_SERVICE_ERROR,
    REQUEST_TERMS_OF_SERVICE_SUCCESS
} from '../Actions/actionTypes';

const requestAuth = (state, action) => {
    return { ...state, isFetching: true };
};

const requestAuthError = (state, action) => {
    return { ...state, isFetching: false };
};

const setUserData = (state, action) => {
    return { ...state, isFetching: false, ...action.payload }
}

const requestChangeTempPass = (state, action) => {
    return { ...state, isFetching: true };
};

const requestChangeTempPassError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestChangeTempPassSuccess = (state, action) => {
    return { ...state, isFetching: false, ...action.payload, changedTempPass: true }
}

const requestAllowTermsOfService = (state, action) => {
    return { ...state, isFetching: true };
};

const requestAllowTermsOfServiceError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestAllowTermsOfServiceSuccess = (state, action) => {
    return { ...state, isFetching: false, ...action.payload }
}

const handleAuxChangeValue = (state, action) => {
    return { ...state, ...action.payload }
}

const requestTermsOfService = (state, action) => {
    return { ...state, isFetching: true }
}
const requestTermsOfServiceError = (state, action) => {
    return { ...state, isFetching: false }
}
const requestTermsOfServiceSuccess = (state, action) => {
    return { ...state, isFetching: false, termsOfService: action.payload }
}


const actions = {
    [REQUEST_AUTH]: requestAuth,
    [REQUEST_AUTH_ERROR]: requestAuthError,
    [SET_USER_DATA]: setUserData,
    [REQUEST_CHANGE_TEMP_PASS]: requestChangeTempPass,
    [REQUEST_CHANGE_TEMP_PASS_ERROR]: requestChangeTempPassError,
    [REQUEST_CHANGE_TEMP_PASS_SUCCESS]: requestChangeTempPassSuccess,
    [REQUEST_CHANGE_TEMP_PASS]: requestChangeTempPass,
    [REQUEST_ALLOW_TERMS_OF_SERVICE]: requestAllowTermsOfService,
    [REQUEST_ALLOW_TERMS_OF_SERVICE_SUCCESS]: requestAllowTermsOfServiceSuccess,
    [REQUEST_ALLOW_TERMS_OF_SERVICE_ERROR]: requestAllowTermsOfServiceError,
    [HANDLE_AUX_CHANGE_VALUE]: handleAuxChangeValue,
    [REQUEST_TERMS_OF_SERVICE]: requestTermsOfService,
    [REQUEST_TERMS_OF_SERVICE_ERROR]: requestTermsOfServiceError,
    [REQUEST_TERMS_OF_SERVICE_SUCCESS]: requestTermsOfServiceSuccess
};

export const auth = (state = initialState.auth, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
