import { PortTerminalService, alertService } from '../Services'
import * as types from './actionTypes'

const portTerminalService = new PortTerminalService();

export const requestPortTerminals = () => ({
    type: types.REQUEST_PORT_TERMINALS,
    payload: null
});

export const requestPortTerminalsSuccess = portTerminals => ({
    type: types.REQUEST_PORT_TERMINALS_SUCCESS,
    payload: portTerminals
});

export const requestPortTerminalsError = error => ({
    type: types.REQUEST_PORT_TERMINALS_ERROR,
    payload: error
});

export const fetchPortTerminals = () => dispatch => {
    dispatch(requestPortTerminals());
    portTerminalService
        .findAll()
        .then(response => {
            if (response.data) {
                const portTerminals = response.data.results;
                const portTerminalsOptions = portTerminals.map(portTerminal => {
                    return {
                        value: portTerminal.id,
                        label: portTerminal.tradeName,
                        active: portTerminal.active
                    };
                });

                dispatch(requestPortTerminalsSuccess(portTerminalsOptions));
            }
        })
        .catch(error => {
            dispatch(requestPortTerminalsError(error));
            alertService.error(error, 'Erro ao buscar terminais portuários');
        });
};