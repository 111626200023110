import { CityService, alertService } from '../Services'
import * as types from './actionTypes'

const cityService = new CityService();

export const requestCities = () => ({
    type: types.REQUEST_CITIES,
    payload: null
});

export const requestCitiesSuccess = activityTypes => ({
    type: types.REQUEST_CITIES_SUCCESS,
    payload: activityTypes
});

export const requestCitiesError = error => ({
    type: types.REQUEST_CITIES_ERROR,
    payload: error
});

export const fetchCities = filter => dispatch => {
    dispatch(requestCities());
    cityService
        .findAll(filter)
        .then(response => {
            if (response.data) {
                const cities = response.data;
                const options = cities.map(city => {
                    return {
                        value: city.id,
                        label: city.name
                    };
                });

                dispatch(requestCitiesSuccess({ options }));
            }
        })
        .catch(error => {
            dispatch(requestCitiesError(error));
            alertService.error(error, 'Erro ao buscar cidades');
        });
};

export const fetchSuggestedCities = filter => dispatch => {
    dispatch(requestCities());
    cityService
        .findAll(filter)
        .then(response => {
            if (response.data) {
                const cities = response.data;
                const suggestedOptions = cities.map(city => {
                    return {
                        value: city.id,
                        label: city.name
                    };
                });

                dispatch(requestCitiesSuccess({ suggestedOptions }));
            }
        })
        .catch(error => {
            dispatch(requestCitiesError(error));
            alertService.error(error, 'Erro ao buscar cidades sugeridas');
        });
};