import React, { useState } from "react";
import { redirectUtils, userDataStorageUtils } from "../../Utils";

export default function SideMenu({
  toggleNotifications,
  toggleContactModal,
  showNotifications,
  notificationsContainer,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="nav-container-mobile">
        <input
          type="checkbox"
          className="openSidebarMenu"
          id="openSidebarMenu"
          checked={isOpen}
          readOnly
          disabled
        />
        <label
          htmlFor="openSidebarMenu"
          className="sidebarIconToggle"
          onClick={() => setIsOpen(!isOpen)}
          style={{ position: `${isOpen ? "fixed" : "absolute"}` }}
        >
          <div className="spinner diagonal part-1"></div>
          <div className="spinner horizontal"></div>
          <div className="spinner diagonal part-2"></div>
        </label>
        {isOpen && (
          <div id="sidebarMenu">
            <ul className="sidebarMenuInner">
              <li onClick={() => setIsOpen(!isOpen)}>
                <a href="#/">Indicativos de Preços</a>
              </li>
              <li onClick={() => setIsOpen(!isOpen)}>
                <a href="#/offer">Criar uma Oferta</a>
              </li>
              <li onClick={() => setIsOpen(!isOpen)}>
                <a href="#/myNegotiations">Minhas Negociações</a>
              </li>
              <li onClick={() => toggleContactModal()}>
                <span>Contato</span>
              </li>
              <li onClick={() => toggleNotifications(!showNotifications)}>
                <span>Notificações</span>
              </li>
              {showNotifications && (
                <li className="notifications">{notificationsContainer}</li>
              )}
              <li className="my-account">
                <span>Minha Conta</span>
                <ul>
                  <li>
                    <a
                      href="#/changePassword"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      Alterar Senha
                    </a>
                  </li>
                  <li>
                    <a href="#/terms" onClick={() => setIsOpen(!isOpen)}>
                      Termos de uso
                    </a>
                  </li>
                </ul>
              </li>
              <li className="logout">
                <span
                  onClick={() => {
                    userDataStorageUtils.removeAuth();
                    redirectUtils.redirectTo("#/login");
                  }}
                >
                  Sair
                </span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
