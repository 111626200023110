import React from "react";
import { useDispatch } from "react-redux";
import { userChangePassActions } from "../../Actions";
import { useForm } from "../../Hooks";

const ForgotPasswordModal = ({ hide }) => {
  const dispatch = useDispatch();

  function handleForgotPassword(data) {
    dispatch(userChangePassActions.requestForgotPasswordEmail(data));
    hide();
  }

  const { values, handleChange, handleSubmit } = useForm({ email: "" }, () =>
    handleForgotPassword(values)
  );

  return (
    <div className="forgot-password-modal">
      <form onSubmit={handleSubmit}>
        <div className="title-container">
          <h2>Esqueci minha senha</h2>
        </div>
        <div className="mail-section text-black">
          <span className="text-left d-block mb-2">Informe seu e-mail:</span>
          <input
            name="email"
            value={values.email}
            onChange={handleChange}
            className="mb-5 login-input"
            autoFocus
          />
        </div>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn login-btn"
            type="submit"
            disabled={values.email.length === 0}
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordModal;
