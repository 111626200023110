import initialState from './initialState'
import {
    REQUEST_SIGNALR_CONNECTION,
    REQUEST_SIGNALR_CONNECTION_SUCCESS,
    REQUEST_SIGNALR_CONNECTION_ERROR,
    CHECK_CMA_CONNECTION,
    CHECK_CMA_CONNECTION_SUCCESS,
    CHECK_CMA_CONNECTION_ERROR,
    SUBSCRIBE_COTATIONS,
    SUBSCRIBE_COTATIONS_SUCCESS,
    SUBSCRIBE_COTATIONS_ERROR,
    SUBSCRIBE_EXCHANGES,
    SUBSCRIBE_EXCHANGES_SUCCESS,
    SUBSCRIBE_EXCHANGES_ERROR,
    CLOSE_SIGNALR_CONNECTION,
    CLOSE_SIGNALR_CONNECTION_SUCCESS,
    CLOSE_SIGNALR_CONNECTION_ERROR,
    SET_EXCHANGES_INFO,
    UNSUBSCRIBE_COTATIONS,
    UNSUBSCRIBE_COTATIONS_SUCCESS,
    UNSUBSCRIBE_COTATIONS_ERROR,
    UNSUBSCRIBE_EXCHANGES,
    UNSUBSCRIBE_EXCHANGES_SUCCESS,
    UNSUBSCRIBE_EXCHANGES_ERROR,
    UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES,
    UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES_SUCCESS,
    UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES_ERROR,
    HANDLE_SIGNAL_R_VALUE_CHANGE
} from '../Actions/actionTypes'

const requestConnection = (state, action) => {
    return { ...state, isConnecting: true };
};

const requestConnectionSuccess = (state, action) => {
    return {
        ...state,
        isConnecting: false,
        isConnected: true
    };
};

const requestConnectionError = (state, action) => {
    return {
        ...state,
        isConnecting: false,
        isConnected: false
    };
};

const checkCMAConnection = (state, action) => {
    return { ...state };
};

const checkCMAConnectionSuccess = (state, action) => {
    return { ...state, isCMAConnected: action.payload };
};

const checkCMAConnectionError = (state, action) => {
    return { ...state, isCMAConnected: false };
};

const subscribeCotations = (state, action) => {
    return { ...state, [action.payload.controlVariable]: true }
}

const subscribeCotationsSuccess = (state, action) => {
    return { ...state, ...action.payload }
}

const subscribeCotationsError = (state, action) => {
    return { ...state, ...action.payload }
}

const subscribeExchanges = (state, action) => {
    return { ...state, [action.payload.controlVariable]: true }
}

const subscribeExchangesSuccess = (state, action) => {
    return { ...state, ...action.payload }
}

const subscribeExchangesError = (state, action) => {
    return { ...state, ...action.payload }
}

const closeConnection = (state, action) => {
    return initialState.signalR
}

const closeConnectionSuccess = (state, action) => {
    return initialState.signalR
}

const closeConnectionError = (state, action) => {
    return { ...state, ...action.payload }
}

const setExchangesInfo = (state, action) => {
    return {
        ...state,
        exchanges: {
            ...state.exchanges,
            ...action.payload
        }
    }
}

const unsubscribeCotations = (state, action) => {
    return { ...state }
}

const unsubscribeCotationsSuccess = (state, action) => {
    return { ...state, [action.payload]: false }
}

const unsubscribeCotationsError = (state, action) => {
    return { ...state }
}

const unsubscribeExchanges = (state, action) => {
    return { ...state }
}

const unsubscribeExchangesSuccess = (state, action) => {
    return { ...state, [action.payload]: false }
}

const unsubscribeExchangesError = (state, action) => {
    return { ...state }
}

const unsubscribeAllCotationsAndExchanges = (state, action) => {
    return { ...state }
}
const unsubscribeAllCotationsAndExchangesSuccess = (state, action) => {
    return {
        ...state,
        indicationsCotationSubscribed: false,
        indicationsExchangeSubscribed: false,
        todayOffersCotationSubscribed: false,
        todayOffersExchangeSubscribed: false,
    }
}

const unsubscribeAllCotationsAndExchangesError = (state, action) => {
    return { ...state }
}

const handleValueChange = (state, action) => {
    return {
        ...state,
        ...action.payload,
    }
}

const actions = {
    [REQUEST_SIGNALR_CONNECTION]: requestConnection,
    [REQUEST_SIGNALR_CONNECTION_SUCCESS]: requestConnectionSuccess,
    [REQUEST_SIGNALR_CONNECTION_ERROR]: requestConnectionError,
    [CHECK_CMA_CONNECTION]: checkCMAConnection,
    [CHECK_CMA_CONNECTION_SUCCESS]: checkCMAConnectionSuccess,
    [CHECK_CMA_CONNECTION_ERROR]: checkCMAConnectionError,
    [SUBSCRIBE_COTATIONS]: subscribeCotations,
    [SUBSCRIBE_COTATIONS_SUCCESS]: subscribeCotationsSuccess,
    [SUBSCRIBE_COTATIONS_ERROR]: subscribeCotationsError,
    [SUBSCRIBE_EXCHANGES]: subscribeExchanges,
    [SUBSCRIBE_EXCHANGES_SUCCESS]: subscribeExchangesSuccess,
    [SUBSCRIBE_EXCHANGES_ERROR]: subscribeExchangesError,
    [CLOSE_SIGNALR_CONNECTION]: closeConnection,
    [CLOSE_SIGNALR_CONNECTION_SUCCESS]: closeConnectionSuccess,
    [CLOSE_SIGNALR_CONNECTION_ERROR]: closeConnectionError,
    [SET_EXCHANGES_INFO]: setExchangesInfo,
    [UNSUBSCRIBE_COTATIONS]: unsubscribeCotations,
    [UNSUBSCRIBE_COTATIONS_SUCCESS]: unsubscribeCotationsSuccess,
    [UNSUBSCRIBE_COTATIONS_ERROR]: unsubscribeCotationsError,
    [UNSUBSCRIBE_EXCHANGES]: unsubscribeExchanges,
    [UNSUBSCRIBE_EXCHANGES_SUCCESS]: unsubscribeExchangesSuccess,
    [UNSUBSCRIBE_EXCHANGES_ERROR]: unsubscribeExchangesError,
    [UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES]: unsubscribeAllCotationsAndExchanges,
    [UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES_SUCCESS]: unsubscribeAllCotationsAndExchangesSuccess,
    [UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES_ERROR]: unsubscribeAllCotationsAndExchangesError,
    [HANDLE_SIGNAL_R_VALUE_CHANGE]: handleValueChange
};

export const signalR = (state = initialState.signalR, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
