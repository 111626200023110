import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Pagination extends Component {

    render() {
        const totalPages = Math.ceil(
            this.props.totalItems / this.props.itemsPerPage
        );
        let numberOfOptions =
            totalPages > (this.props.maxNumberOfOptions || totalPages)
                ? this.props.maxNumberOfOptions
                : totalPages;
        const currentPage = this.props.currentPage;
        const pageIndex =
            this.props.currentPage + 1 > this.totalPages
                ? this.totalpages
                : this.props.currentPage + 1;
        return numberOfOptions > 1 ? (
            <div className='pagination-container'>
                <div disabled={this.props.isLoading}>
                    <div
                        onClick={() => {
                            this.props.goToPage(0);
                        }}
                        disabled={currentPage === 0}
                        className='no-box-shadow'
                    >
                        <i className='fa fa-angle-double-left' />
                    </div>
                </div>
                <div disabled={this.props.isLoading}>
                    <div
                        onClick={() => {
                            this.props.goToPage(this.props.currentPage - 1);
                        }}
                        disabled={currentPage === 0}
                        className='no-box-shadow'
                    >
                        <i className='fa fa-angle-left' />

                    </div>
                </div>
                <div
                    className='pagination-index'
                    style={{
                        display: 'flex',
                        padding: '0px 10px 0px 10px',
                        borderRadius: '10px',
                        height: '34px',
                        width: '105px',
                        justifyContent: 'center',
                        border: '1px solid #dee2e6',
                        borderLeft: 'none',
                        backgroundColor: 'transparent'
                    }}
                >
                    <span className='my-auto mx-1'>{pageIndex} de {totalPages}</span>
                </div>
                <div disabled={this.props.isLoading}>
                    <div
                        onClick={() => {
                            this.props.goToPage(this.props.currentPage + 1);
                        }}
                        disabled={currentPage === totalPages - 1}
                        className='no-box-shadow'
                    >
                        <i className='fa fa-angle-right' />
                    </div>
                </div>
                <div disabled={this.props.isLoading}>
                    <div
                        onClick={() => {
                            this.props.goToPage(totalPages - 1);
                        }}
                        disabled={currentPage === totalPages - 1}
                        className='no-box-shadow'
                    >
                        <i className='fa fa-angle-double-right' />
                    </div>
                </div>
            </div>
        ) : null;
    }
}

Pagination.propTypes = {
    currentPage: PropTypes.number,
    goToPage: PropTypes.func,
    totalItems: PropTypes.number,
    itemsPerPage: PropTypes.number,
    maxNumberOfOptions: PropTypes.number,
    isLoading: PropTypes.bool
};

export default Pagination;
