import initialState from './initialState';
import { REQUEST_NOTIFICATIONS, REQUEST_NOTIFICATIONS_SUCCESS, REQUEST_NOTIFICATIONS_ERROR, REQUEST_NOTIFICATION_READ, REQUEST_NOTIFICATION_READ_SUCCESS, REQUEST_NOTIFICATION_READ_ERROR } from '../Actions/actionTypes';

const requestNotifications = (state, action) => {
    return {
        ...state,
        isFetching: true
    };
};

const requestNotificationsSuccess = (state, action) => {
    return {
        ...state,
        entities: action.payload,
        isFetching: false
    };
};

const requestNotificationsError = (state, action) => {
    return {
        ...state,
        isFetching: false
    };
};

const requestNotificationRead = (state, action) => {
    return {
        ...state
    };
};

const requestNotificationReadSuccess = (state, action) => {
    const entities = state.entities.map(notification => {
        if (notification.id === action.payload.id) return action.payload
        return notification
    })
    return {
        ...state,
        entities,
    };
};

const requestNotificationReadError = (state, action) => {
    return {
        ...state,
    };
};


const actions = {
    [REQUEST_NOTIFICATIONS]: requestNotifications,
    [REQUEST_NOTIFICATIONS_SUCCESS]: requestNotificationsSuccess,
    [REQUEST_NOTIFICATIONS_ERROR]: requestNotificationsError,
    [REQUEST_NOTIFICATION_READ]: requestNotificationRead,
    [REQUEST_NOTIFICATION_READ_SUCCESS]: requestNotificationReadSuccess,
    [REQUEST_NOTIFICATION_READ_ERROR]: requestNotificationReadError,
};

export const notifications = (state = initialState.notifications, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
