import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { redirectUtils, userDataStorageUtils } from "../../Utils";
import { useOuterClickNotifier, useModal } from "../../Hooks";
import { Modal, ContactModal } from "../../Components";
import SideMenu from "./sideMenu";
import { authActions } from "../../Actions";
import * as moment from "moment";

export default function Header(props) {
  const [showNotifications, toggleNotifications] = useState(false);
  const [showSettings, toggleSettings] = useState(false);
  const [selectedPath, setSelectedPath] = useState("#/");

  const { isShowing: isContactModalShowing, toggle: toggleContactModal } =
    useModal();
  const unreadCounter = props.notifications.filter(
    (notification) => !notification.read
  ).length;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      authActions.handleChangeValue(
        window.location.href.slice(window.location.href.lastIndexOf("/"))
      )
    );
    // eslint-disable-next-line
  }, []);

  const clickHandler = (path) => {
    setSelectedPath(path);
    redirectUtils.redirectTo(path);
  };

  return (
    <div className="header-background-container">
      <div className="header-container">
        <div className="logo-container">
          <i
            className="ic-logo-orange-no-text"
            onClick={() => clickHandler("#/")}
          ></i>
          <i className="ic-logo-orange" onClick={() => clickHandler("#/")}></i>
        </div>
        <SideMenu
          toggleNotifications={toggleNotifications}
          unreadCounter={unreadCounter}
          toggleSettings={toggleSettings}
          toggleContactModal={toggleContactModal}
          showNotifications={showNotifications}
          showSettings={showSettings}
          notificationsContainer={
            <NotifcationsContainer
              notifications={props.notifications}
              showNotifications={showNotifications}
              hide={() => toggleNotifications(false)}
              onNotificationRead={props.onNotificationRead}
            />
          }
          userSettingsContainer={
            <UserSettingsContainer
              notifications={props.notifications}
              showSettings={showSettings}
              hide={() => toggleSettings(false)}
            />
          }
        />
        <div className="nav-container">
          <div
            className={`${selectedPath === "#/" ? "selected" : ""} nav-item`}
            onClick={() => clickHandler("#/")}
          >
            <span>Indicativos de Preços</span>
          </div>
          <div
            className={`${
              selectedPath === "#/offer" ? "selected" : ""
            } nav-item`}
            onClick={() => clickHandler("#/offer")}
          >
            <span>Criar uma Oferta</span>
          </div>
          <div
            className={`${
              selectedPath === "#/myNegotiations" ? "selected" : ""
            } nav-item`}
            onClick={() => clickHandler("#/myNegotiations")}
          >
            <span>Minhas Negociações</span>
          </div>
          <div className="nav-item" onClick={() => toggleContactModal()}>
            <span>Contato</span>
          </div>
        </div>
        <div className="right-menu">
          <div
            className="nav-btn"
            onClick={() => toggleNotifications(!showNotifications)}
          >
            <i className="fa fa-bell" />
            {unreadCounter > 0 && (
              <span className="notification-badge">
                {unreadCounter > 9 ? "9+" : unreadCounter}
              </span>
            )}
          </div>
          <div className="nav-btn">
            <i
              className="fa fa-user"
              onClick={() => toggleSettings(!showSettings)}
            />
          </div>
        </div>
        <NotifcationsContainer
          notifications={props.notifications}
          showNotifications={showNotifications}
          hide={() => toggleNotifications(false)}
          onNotificationRead={props.onNotificationRead}
        />
        <UserSettingsContainer
          notifications={props.notifications}
          showSettings={showSettings}
          hide={() => toggleSettings(false)}
        />
      </div>
      <Modal
        isShowing={isContactModalShowing}
        hide={toggleContactModal}
        isLoading={false}
        content={<ContactModal />}
      />
    </div>
  );
}

const NotifcationsContainer = (props) => {
  const innerRef = useRef(null);
  useOuterClickNotifier(() => {
    if (props.showNotifications) props.hide();
  }, innerRef);

  const notificationClickHandler = (notification) => {
    switch (notification.description) {
      case "Você recebeu uma nova proposta para sua oferta":
        redirectUtils.redirectTo(`#/counterOffer/${notification.targetEntity}`);
        props.hide();
        props.onNotificationRead(notification.id);
        break;
      default:
        props.hide();
        redirectUtils.redirectTo("#/myNegotiations");
        props.onNotificationRead(notification.id);
        break;
    }
  };

  return (
    props.showNotifications && (
      <div ref={innerRef} className="notifications-container">
        {props.notifications.length !== 0 ? (
          props.notifications.map((notification) => {
            const isRead = notification.read;

            const notificationStyle = isRead
              ? {
                  cursor: "default",
                  opacity: 0.5,
                }
              : {
                  borderRight: "5px solid #88C341",
                  cursor: "pointer",
                  opacity: 1,
                };

            return (
              <div
                className="notification"
                style={notificationStyle}
                onClick={
                  isRead ? null : () => notificationClickHandler(notification)
                }
                key={notification.id}
              >
                <p>
                  {moment
                    .utc(notification.createdOn)
                    .local()
                    .format("DD/MM/YY HH:mm")}{" "}
                  - {notification.description}
                </p>
              </div>
            );
          })
        ) : (
          <div className="msg-container">
            <span className="empty-msg">Não há notificações</span>
          </div>
        )}
      </div>
    )
  );
};

const UserSettingsContainer = (props) => {
  const userData = userDataStorageUtils.getUserData();
  const innerRef = useRef(null);
  useOuterClickNotifier(() => {
    if (props.showSettings) props.hide();
  }, innerRef);

  return (
    props.showSettings && (
      <div ref={innerRef} className="user-settings-container">
        <div className="info">
          <span className="title">{userData && userData.name}</span>
          {userData && userData.clientTradeName && (
            <span className="subtitle">{userData.clientTradeName}</span>
          )}
        </div>
        <div
          className="menu-item"
          onClick={() => {
            redirectUtils.redirectTo("#/changePassword");
            props.hide();
          }}
        >
          <i className="ic-locker" />
          <span>Alterar Senha</span>
        </div>
        <div
          className="menu-item"
          onClick={() => {
            redirectUtils.redirectTo("#/terms");
            props.hide();
          }}
        >
          <i className="ic-use-terms" />
          <span>Termos de Uso</span>
        </div>
        <div className="logout">
          <span
            onClick={() => {
              userDataStorageUtils.removeAuth();
              redirectUtils.redirectTo("#/login");
            }}
          >
            Sair
          </span>
        </div>
      </div>
    )
  );
};
