import * as types from "./actionTypes";
import { UserService, alertService } from "../Services";

const userService = new UserService();

export const requestForgotPassword = (value) => ({
  type: types.REQUEST_FORGOT_PASSWORD,
  payload: value,
});

export const requestForgotPasswordSuccess = (data) => ({
  type: types.REQUEST_FORGOT_PASSWORD_SUCCESS,
  payload: data,
});

export const requestForgotPasswordError = (error) => ({
  type: types.REQUEST_FORGOT_PASSWORD_ERROR,
  payload: error,
});

export const requestForgotPasswordEmail = (data) => (dispatch) => {
  dispatch(requestForgotPassword(data));

  userService.forgotPassword(data);

  alertService.info("Um e-mail foi enviado para a troca de senha.");
};

export const requestChangeUserPass = () => ({
  type: types.REQUEST_CHANGE_USER_PASS,
  payload: null,
});

export const requestChangeUserPassSuccess = (register) => ({
  type: types.REQUEST_CHANGE_USER_PASS_SUCCESS,
  payload: register,
});

export const requestChangeUserPassError = (error) => ({
  type: types.REQUEST_CHANGE_USER_PASS_ERROR,
  payload: error,
});

export const handleChangePass = (values) => (dispatch) => {
  if (values.newPassword.length < 6) {
    alertService.info("A nova senha deve conter no mínimo 6 caracteres");
    return;
  }

  if (values.newPassword !== values.repeatNewPass) {
    alertService.info("A confirmação de senha não confere!");
    return;
  }

  dispatch(requestChangeUserPass());

  userService
    .changePassword(values)
    .then((response) => {
      dispatch(requestChangeUserPassSuccess(response.data));
      alertService.success("Senha alterada com sucesso!");
    })
    .catch((error) => {
      dispatch(requestChangeUserPassError(values));
      const {
        response: {
          data: { message },
        },
      } = error;
      alertService.error(error, message || "Erro ao alterar senha");
    });
};

export const handleValueChange = (edition) => ({
  type: types.HANDLE_CHANGE_USER_PASS_FORM_VALUE_CHANGE,
  payload: edition,
});

export const clear = () => ({
  type: types.CLEAR_CHANGE_USER_PASS_FORM,
  payload: null,
});
