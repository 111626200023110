import React from 'react'
import { redirectUtils } from '../../Utils'

export default function SuccessMessageModal(props) {
    return (
        <div className='success-msg-container'>
            <i className='fa fa-check' />
            <div className='main-message-section'>
                <span>{props.message}</span>
            </div>
            <div className='text-section'>
                {props.description}
            </div>
            <div className='btn-section'>
                <button className='btn inova-btn primary' onClick={() => redirectUtils.redirectTo(props.redirectLink)}>{props.btnText}</button>
            </div>
        </div>
    )
}