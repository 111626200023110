import BaseService from './base.service'

class NotificationService extends BaseService {
  constructor() {
    super('Notification');
  }

  findAllSelf(data = {}) {
    return this.post(data, '/FindAllSelf')
  }

  markAsRead(id) {
    return this.post({}, `/${id}/MarkAsRead`)
  }

}

export default NotificationService;
