import * as types from '../actionTypes'
import { CounterOfferService, alertService } from '../../Services'

const counterOfferService = new CounterOfferService();

export const requestAcceptCounterOffer = () => ({
    type: types.REQUEST_ACCEPT_COUNTER_OFFER,
    payload: null
})

export const requestAcceptCounterOfferSuccess = counterOffer => ({
    type: types.REQUEST_ACCEPT_COUNTER_OFFER_SUCCESS,
    payload: counterOffer
})

export const requestAcceptCounterOfferError = error => ({
    type: types.REQUEST_ACCEPT_COUNTER_OFFER_ERROR,
    payload: error
})

export const confirmCounterOffer = (id, successCallback) => dispatch => {
    dispatch(requestAcceptCounterOffer());
    counterOfferService.confirmCounterOffer(id)
        .then(response => {
            dispatch(requestAcceptCounterOfferSuccess(response));
            successCallback()
        }).catch(error => {
            dispatch(requestAcceptCounterOfferError(error));
            alertService.error(error, 'Erro ao aceitar contra oferta');
        })
}

export const requestRefuseCounterOffer = () => ({
    type: types.REQUEST_REFUSE_COUNTER_OFFER,
    payload: null
})

export const requestRefuseCounterOfferSuccess = counterOffer => ({
    type: types.REQUEST_REFUSE_COUNTER_OFFER_SUCCESS,
    payload: counterOffer
})

export const requestRefuseCounterOfferError = error => ({
    type: types.REQUEST_REFUSE_COUNTER_OFFER_ERROR,
    payload: error
})

export const refuseCounterOffer = (id, closeModalCallback, successCallback) => dispatch => {
    dispatch(requestRefuseCounterOffer());
    counterOfferService.refuseCounterOffer(id)
        .then(response => {
            dispatch(requestRefuseCounterOfferSuccess(response))
            closeModalCallback()
            successCallback()
        }).catch(error => {
            dispatch(requestRefuseCounterOfferError(error));
            alertService.error(error, 'Erro ao recusar contra oferta')
        })
}
