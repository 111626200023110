import React from 'react'

export default function RadioInputCard(props) {

    const clickHandler = () => {
        const event = { target: { name: props.name, value: props.value } }
        props.onChange(event)
    }

    return (
        <div className='radio-input-card' onClick={() => clickHandler()}>
            <input
                id={props.id}
                type='radio'
                disabled={props.disabled}
                checked={props.checked}
                required={props.required}
                name={props.name}
                value={props.value}
                onChange={() => { }}
            />
            <div className='label-container'>
                <div className='top-circle-container'><i className='fa fa-check' /></div>
                <div>
                    <i className={props.iconClass} />
                    <label htmlFor={props.id}>
                        {props.label}
                    </label>
                </div>
            </div>
        </div>
    )
}
