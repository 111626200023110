import initialState from './initialState'
import {
    REQUEST_HARVEST_OPTIONS,
    REQUEST_HARVEST_OPTIONS_SUCCESS,
    REQUEST_HARVEST_OPTIONS_ERROR,
    REQUEST_HARVESTS,
    REQUEST_HARVESTS_SUCCESS,
    REQUEST_HARVESTS_ERROR,
    REQUEST_DELETE_HARVEST,
    REQUEST_DELETE_HARVEST_SUCCESS,
    REQUEST_DELETE_HARVEST_ERROR
} from '../Actions/actionTypes'

const requestHarvests = (state, action) => {
    return { ...state, isFetching: true };
};

const requestHarvestsSuccess = (state, action) => {
    return {
        ...state,
        ...action.payload,
        isFetching: false
    };
};

const requestHarvestsError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestDeleteHarvest = (state, action) => {
    return { ...state, isSaving: true };
};

const requestDeleteHarvestSuccess = (state, action) => {
    return { ...state, isSaving: false };
};

const requestDeleteHarvestError = (state, action) => {
    return { ...state, isSaving: false };
};

const requestHarvestOptions = (state, action) => {
    return { ...state, isFetching: true };
};

const requestHarvestOptionsSuccess = (state, action) => {
    return {
        ...state,
        options: action.payload,
        isFetching: false
    };
};

const requestHarvestOptionsError = (state, action) => {
    return { ...state, isFetching: false };
};

const actions = {
    [REQUEST_HARVESTS]: requestHarvests,
    [REQUEST_HARVESTS_SUCCESS]: requestHarvestsSuccess,
    [REQUEST_HARVESTS_ERROR]: requestHarvestsError,
    [REQUEST_DELETE_HARVEST]: requestDeleteHarvest,
    [REQUEST_DELETE_HARVEST_SUCCESS]: requestDeleteHarvestSuccess,
    [REQUEST_DELETE_HARVEST_ERROR]: requestDeleteHarvestError,
    [REQUEST_HARVEST_OPTIONS]: requestHarvestOptions,
    [REQUEST_HARVEST_OPTIONS_SUCCESS]: requestHarvestOptionsSuccess,
    [REQUEST_HARVEST_OPTIONS_ERROR]: requestHarvestOptionsError,
};

export const harvests = (state = initialState.harvests, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
