import * as types from '../actionTypes'
import { OfferService, alertService } from '../../Services'

const offerService = new OfferService();

export const requestOffersHistory = stateFilters => ({
    type: types.REQUEST_OFFERS_HISTORY,
    payload: stateFilters
})

export const requestOffersHistorySuccess = offers => ({
    type: types.REQUEST_OFFERS_HISTORY_SUCCESS,
    payload: offers
})

export const requestOffersHistoryError = error => ({
    type: types.REQUEST_OFFERS_HISTORY_ERROR,
    payload: error
})

export const fetchOffersHistory = (options, stateFilters) => dispatch => {
    dispatch(requestOffersHistory(stateFilters));
    offerService.findAllHistoryForCurrentUser(options)
        .then(response => {
            if (response.data) {
                dispatch(requestOffersHistorySuccess(response.data));
            }
        }).catch(error => {
            dispatch(requestOffersHistoryError(error));
            alertService.error(error, 'Erro ao buscar histórico de ofertas');
        })
}

export const handleValueChange = edition => ({
    type: types.HANDLE_OFFERS_HISTORY_VALUE_CHANGE,
    payload: edition
});