import initialState from './initialState';
import { REQUEST_SAVE_USER_PRE_REGISTER_FORM, REQUEST_SAVE_USER_PRE_REGISTER_FORM_SUCCESS, REQUEST_SAVE_USER_PRE_REGISTER_FORM_ERROR, CLEAR_USER_PRE_REGISTER_FORM, HANDLE_USER_PRE_REGISTER_FORM_VALUE_CHANGE } from '../Actions/actionTypes';


const requestSaveUserPreRegisterForm = (state, action) => {
    return { ...state, isSaving: true };
};

const requestSaveUserPreRegisterFormSuccess = (state, action) => {
    return { ...initialState.userPreRegisterForm, isSaveComplete: true };
};

const requestSaveUserPreRegisterFormError = (state, action) => {
    return { ...state, isSaving: false, ...action.payload.data };
};

const handleValueChange = (state, action) => {
    return {
        ...state,
        ...action.payload,
    };
};

const clearUserPreRegisterForm = (state, action) => {
    return initialState.userPreRegisterForm
}

const actions = {
    [REQUEST_SAVE_USER_PRE_REGISTER_FORM]: requestSaveUserPreRegisterForm,
    [REQUEST_SAVE_USER_PRE_REGISTER_FORM_SUCCESS]: requestSaveUserPreRegisterFormSuccess,
    [REQUEST_SAVE_USER_PRE_REGISTER_FORM_ERROR]: requestSaveUserPreRegisterFormError,
    [CLEAR_USER_PRE_REGISTER_FORM]: clearUserPreRegisterForm,
    [HANDLE_USER_PRE_REGISTER_FORM_VALUE_CHANGE]: handleValueChange
};

export const userPreRegisterForm = (state = initialState.userPreRegisterForm, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
