export const userDataStorageConstants = {
  KeepAlive: "kcBrickWeb",
  AuthToken: "authBrickWeb",
};

export const productColor = {
  Soja: "#f58520",
  Trigo: "#f58520",
  Milho: "#f58520",
};
