export default function (price, cotation, exchange) {
    const fobbings = price.shippingAgreementTypeFobbings || 0
    const reward = price.reward
    const shippingValue = price.freightValuePerTon
    const multiplierTONtoPriceUnit = price.multiplierTONtoPriceUnit
    const multiplierBUtoTON = price.multiplierBUtoTON
    const cifValue = ((reward + cotation) * multiplierBUtoTON - fobbings) * exchange / multiplierTONtoPriceUnit
    if (price.shippingAgreementTypeCode !== 'FOB')
        return cifValue
    return cifValue - (shippingValue / multiplierTONtoPriceUnit)
}
