import initialState from './initialState';
import { REQUEST_SHIPPING_AGREEMENT_TYPES, REQUEST_SHIPPING_AGREEMENT_TYPES_SUCCESS, REQUEST_SHIPPING_AGREEMENT_TYPES_ERROR } from '../Actions/actionTypes';

const requestShippingAgreementTypes = (state, action) => {
    return { ...state, isFetching: true };
};

const requestShippingAgreementTypesSuccess = (state, action) => {
    return {
        ...state,
        options: action.payload,
        isFetching: false
    };
};

const requestShippingAgreementTypesError = (state, action) => {
    return { ...state, isFetching: false };
};

const actions = {
    [REQUEST_SHIPPING_AGREEMENT_TYPES]: requestShippingAgreementTypes,
    [REQUEST_SHIPPING_AGREEMENT_TYPES_SUCCESS]: requestShippingAgreementTypesSuccess,
    [REQUEST_SHIPPING_AGREEMENT_TYPES_ERROR]: requestShippingAgreementTypesError
};

export const shippingAgreementTypes = (state = initialState.shippingAgreementTypes, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
