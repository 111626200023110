import * as types from './actionTypes'
import { UserPreRegisterService, alertService } from './../Services'
import { dateUtils } from '../Utils';

const userPreRegisterService = new UserPreRegisterService();

export const requestSaveUserPreRegisterForm = () => ({
    type: types.REQUEST_SAVE_USER_PRE_REGISTER_FORM,
    payload: null
});

export const requestSaveUserPreRegisterFormSuccess = register => ({
    type: types.REQUEST_SAVE_USER_PRE_REGISTER_FORM_SUCCESS,
    payload: register
});

export const requestSaveUserPreRegisterFormError = error => ({
    type: types.REQUEST_SAVE_USER_PRE_REGISTER_FORM_ERROR,
    payload: error
});

export const handleSave = register => dispatch => {
    dispatch(requestSaveUserPreRegisterForm())
    userPreRegisterService
        .saveOrUpdate({ ...register, bornOn: dateUtils.format(register.bornOn, 'YYYY-MM-DD', 'DD/MM/YYYY') })
        .then(response => {
            dispatch(requestSaveUserPreRegisterFormSuccess(response.data))
        })
        .catch(error => {
            dispatch(requestSaveUserPreRegisterFormError({ data: register, error }))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao cadastrar')
        })
}

export const handleValueChange = edition => ({
    type: types.HANDLE_USER_PRE_REGISTER_FORM_VALUE_CHANGE,
    payload: edition
});

export const clear = () => ({
    type: types.CLEAR_USER_PRE_REGISTER_FORM,
    payload: null
});