//--------------------- Authorization
export const REQUEST_AUTH = 'REQUEST_AUTH'
export const REQUEST_AUTH_SUCCESS = 'REQUEST_AUTH_SUCCESS'
export const REQUEST_AUTH_ERROR = 'REQUEST_AUTH_ERROR'
export const SET_USER_DATA = 'SET_USER_DATA'

export const REQUEST_CHANGE_TEMP_PASS = 'REQUEST_CHANGE_TEMP_PASSS'
export const REQUEST_CHANGE_TEMP_PASS_ERROR = 'REQUEST_CHANGE_TEMP_PASS_ERROR'
export const REQUEST_CHANGE_TEMP_PASS_SUCCESS = 'REQUEST_CHANGE_TEMP_PASS_SUCCESS'

export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD'
export const REQUEST_FORGOT_PASSWORD_SUCCESS = 'REQUEST_FORGOT_PASSWORD_SUCCESS'
export const REQUEST_FORGOT_PASSWORD_ERROR = 'REQUEST_FORGOT_PASSWORD_ERROR'

export const REQUEST_ALLOW_TERMS_OF_SERVICE = 'REQUEST_ALLOW_TERMS_OF_SERVICE'
export const REQUEST_ALLOW_TERMS_OF_SERVICE_SUCCESS = 'REQUEST_ALLOW_TERMS_OF_SERVICE_SUCCESS'
export const REQUEST_ALLOW_TERMS_OF_SERVICE_ERROR = 'REQUEST_ALLOW_TERMS_OF_SERVICE_ERROR'

export const REQUEST_TERMS_OF_SERVICE = 'REQUEST_TERMS_OF_SERVICE'
export const REQUEST_TERMS_OF_SERVICE_ERROR = 'REQUEST_TERMS_OF_SERVICE_ERROR'
export const REQUEST_TERMS_OF_SERVICE_SUCCESS = 'REQUEST_TERMS_OF_SERVICE_SUCCESS'

export const HANDLE_AUX_CHANGE_VALUE = 'HANDLE_AUX_CHANGE_VALUE'

//---------------------- Activity Type
export const REQUEST_ACTIVITY_TYPES = 'REQUEST_ACTIVITY_TYPES'
export const REQUEST_ACTIVITY_TYPES_SUCCESS = 'REQUEST_ACTIVITY_TYPES_SUCCESS'
export const REQUEST_ACTIVITY_TYPES_ERROR = 'REQUEST_ACTIVITY_TYPES_ERROR'

//---------------------- City
export const REQUEST_CITIES = 'REQUEST_CITIES'
export const REQUEST_CITIES_SUCCESS = 'REQUEST_CITIES_SUCCESS'
export const REQUEST_CITIES_ERROR = 'REQUEST_CITIES_ERROR'

//---------------------- Offer

export const REQUEST_TODAY_OFFERS = 'REQUEST_TODAY_OFFERS'
export const REQUEST_TODAY_OFFERS_SUCCESS = 'REQUEST_TODAY_OFFERS_SUCCESS'
export const REQUEST_TODAY_OFFERS_ERROR = 'REQUEST_TODAY_OFFERS_ERROR'

export const REQUEST_OFFER = 'REQUEST_OFFER'
export const REQUEST_OFFER_SUCCESS = 'REQUEST_OFFER_SUCCESS'
export const REQUEST_OFFER_ERROR = 'REQUEST_OFFER_ERROR'

export const REQUEST_CANCEL_OFFER = 'REQUEST_CANCEL_OFFER'
export const REQUEST_CANCEL_OFFER_SUCCESS = 'REQUEST_CANCEL_OFFER_SUCCESS'
export const REQUEST_CANCEL_OFFER_ERROR = 'REQUEST_CANCEL_OFFER_ERROR'

export const HANDLE_OFFER_VALUE_CHANGE = 'HANDLE_OFFER_VALUE_CHANGE'

//---------------------- Offer Form
export const REQUEST_SAVE_OFFER_FORM = 'REQUEST_SAVE_OFFER_FORM'
export const REQUEST_SAVE_OFFER_FORM_SUCCESS = 'REQUEST_SAVE_OFFER_FORM_SUCCESS'
export const REQUEST_SAVE_OFFER_FORM_ERROR = 'REQUEST_SAVE_OFFER_FORM_ERROR'
export const HANDLE_OFFER_FORM_VALUE_CHANGE = 'HANDLE_OFFER_FORM_VALUE_CHANGE'
export const CLEAR_OFFER_FORM = 'CLEAR_OFFER_FORM'

//---------------------- Offer History

export const REQUEST_OFFERS_HISTORY = 'REQUEST_OFFERS_HISTORY'
export const REQUEST_OFFERS_HISTORY_SUCCESS = 'REQUEST_OFFERS_HISTORY_SUCCESS'
export const REQUEST_OFFERS_HISTORY_ERROR = 'REQUEST_OFFERS_HISTORY_ERROR'
export const HANDLE_OFFERS_HISTORY_VALUE_CHANGE = 'HANDLE_OFFERS_HISTORY_VALUE_CHANGE'

export const REQUEST_NEGOTIATION_CONTRACT = 'REQUEST_NEGOTIATION_CONTRACT'
export const REQUEST_NEGOTIATION_CONTRACT_SUCCESS = 'REQUEST_NEGOTIATION_CONTRACT_SUCCESS'
export const REQUEST_NEGOTIATION_CONTRACT_ERROR = 'REQUEST_NEGOTIATION_CONTRACT_ERROR'

//---------------------- Counter Offer

export const REQUEST_ACCEPT_COUNTER_OFFER = 'REQUEST_ACCEPT_COUNTER_OFFER'
export const REQUEST_ACCEPT_COUNTER_OFFER_SUCCESS = 'REQUEST_ACCEPT_COUNTER_OFFER_SUCCESS'
export const REQUEST_ACCEPT_COUNTER_OFFER_ERROR = 'REQUEST_ACCEPT_COUNTER_OFFER_ERROR'
export const REQUEST_REFUSE_COUNTER_OFFER = 'REQUEST_REFUSE_COUNTER_OFFER'
export const REQUEST_REFUSE_COUNTER_OFFER_SUCCESS = 'REQUEST_REFUSE_COUNTER_OFFER_SUCCESS'
export const REQUEST_REFUSE_COUNTER_OFFER_ERROR = 'REQUEST_REFUSE_COUNTER_OFFER_ERROR'

//---------------------- Register

export const REQUEST_SAVE_USER_PRE_REGISTER_FORM = 'REQUEST_SAVE_USER_PRE_REGISTER_FORM'
export const REQUEST_SAVE_USER_PRE_REGISTER_FORM_SUCCESS = 'REQUEST_SAVE_USER_PRE_REGISTER_FORM_SUCCESS'
export const REQUEST_SAVE_USER_PRE_REGISTER_FORM_ERROR = 'REQUEST_SAVE_USER_PRE_REGISTER_FORM_ERROR'
export const CLEAR_USER_PRE_REGISTER_FORM = 'CLEAR_USER_PRE_REGISTER_FORM'
export const HANDLE_USER_PRE_REGISTER_FORM_VALUE_CHANGE = 'HANDLE_USER_PRE_REGISTER_FORM_VALUE_CHANGE'

//---------------------- Change Pass

export const REQUEST_CHANGE_USER_PASS = 'REQUEST_CHANGE_USER_PASS'
export const REQUEST_CHANGE_USER_PASS_SUCCESS = 'REQUEST_CHANGE_USER_PASS_SUCCESS'
export const REQUEST_CHANGE_USER_PASS_ERROR = 'REQUEST_CHANGE_USER_PASS_ERROR'
export const HANDLE_CHANGE_USER_PASS_FORM_VALUE_CHANGE = 'HANDLE_CHANGE_USER_PASS_FORM_VALUE_CHANGE'
export const CLEAR_CHANGE_USER_PASS_FORM = 'CLEAR_CHANGE_USER_PASS_FORM'

//----------------------- Notification

export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS'
export const REQUEST_NOTIFICATIONS_SUCCESS = 'REQUEST_NOTIFICATIONS_SUCCESS'
export const REQUEST_NOTIFICATIONS_ERROR = 'REQUEST_NOTIFICATIONS_ERROR'

export const REQUEST_NOTIFICATION_READ = 'REQUEST_NOTIFICATION_READ'
export const REQUEST_NOTIFICATION_READ_SUCCESS = 'REQUEST_NOTIFICATION_READ_SUCCESS'
export const REQUEST_NOTIFICATION_READ_ERROR = 'REQUEST_NOTIFICATION_READ_ERROR'

//---------------------- Prices
//---------------------- Prices Indications
export const HANDLE_PRICES_INDICATIONS_VALUE_CHANGE = 'HANDLE_PRICES_INDICATIONS_VALUE_CHANGE'
export const REQUEST_PUBLISHED_PRICES = 'REQUEST_PUBLISHED_PRICES'
export const REQUEST_PUBLISHED_PRICES_SUCCESS = 'REQUEST_PUBLISHED_PRICES_SUCCESS'
export const REQUEST_PUBLISHED_PRICES_ERROR = 'REQUEST_PUBLISHED_PRICES_ERROR'

export const REQUEST_UPDATE_PRICE_VALUE = 'REQUEST_UPDATE_PRICE_VALUE'
export const REQUEST_UPDATE_PRICE_VALUE_SUCCESS = 'REQUEST_UPDATE_PRICE_VALUE_SUCCESS'
export const REQUEST_UPDATE_PRICE_VALUE_ERROR = 'REQUEST_UPDATE_PRICE_VALUE_ERROR'

export const REQUEST_UNPUBLISH_PRICE = 'REQUEST_UNPUBLISH_PRICE'
export const REQUEST_UNPUBLISH_PRICE_SUCCESS = 'REQUEST_UNPUBLISH_PRICE_SUCCESS'
export const REQUEST_UNPUBLISH_PRICE_ERROR = 'REQUEST_UNPUBLISH_PRICE_ERROR'

export const PRICE_INDICATIONS_COTATION_UPDATE = 'PRICE_INDICATIONS_COTATION_UPDATE'
export const PRICE_INDICATIONS_EXCHANGE_UPDATE = 'PRICE_INDICATIONS_EXCHANGE_UPDATE'

export const OFFERS_COTATION_UPDATE = 'OFFERS_COTATION_UPDATE'
export const OFFERS_EXCHANGE_UPDATE = 'OFFERS_EXCHANGE_UPDATE'

//---------------------- Product
export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS'
export const REQUEST_PRODUCTS_SUCCESS = 'REQUEST_PRODUCTS_SUCCESS'
export const REQUEST_PRODUCTS_ERROR = 'REQUEST_PRODUCTS_ERROR'

//---------------------- Harvest
export const REQUEST_HARVESTS = 'REQUEST_HARVESTS'
export const REQUEST_HARVESTS_SUCCESS = 'REQUEST_HARVESTS_SUCCESS'
export const REQUEST_HARVESTS_ERROR = 'REQUEST_HARVESTS_ERROR'

export const REQUEST_DELETE_HARVEST = 'REQUEST_DELETE_HARVEST'
export const REQUEST_DELETE_HARVEST_SUCCESS = 'REQUEST_DELETE_HARVEST_SUCCESS'
export const REQUEST_DELETE_HARVEST_ERROR = 'REQUEST_DELETE_HARVEST_ERROR'

export const REQUEST_HARVEST_OPTIONS = 'REQUEST_HARVEST_OPTIONS'
export const REQUEST_HARVEST_OPTIONS_SUCCESS = 'REQUEST_HARVEST_OPTIONS_SUCCESS'
export const REQUEST_HARVEST_OPTIONS_ERROR = 'REQUEST_HARVEST_OPTIONS_ERROR'

//---------------------- Port Terminal
export const REQUEST_PORT_TERMINALS = 'REQUEST_PORT_TERMINALS'
export const REQUEST_PORT_TERMINALS_SUCCESS = 'REQUEST_PORT_TERMINALS_SUCCESS'
export const REQUEST_PORT_TERMINALS_ERROR = 'REQUEST_PORT_TERMINALS_ERROR'

//---------------------- Shipping Agreement Type
export const REQUEST_SHIPPING_AGREEMENT_TYPES = 'REQUEST_SHIPPING_AGREEMENT_TYPES'
export const REQUEST_SHIPPING_AGREEMENT_TYPES_SUCCESS = 'REQUEST_SHIPPING_AGREEMENT_TYPES_SUCCESS'
export const REQUEST_SHIPPING_AGREEMENT_TYPES_ERROR = 'REQUEST_SHIPPING_AGREEMENT_TYPES_ERROR'

//---------------------- Freight
export const REQUEST_FREIGHTS = 'REQUEST_FREIGHTS'
export const REQUEST_FREIGHTS_SUCCESS = 'REQUEST_FREIGHTS_SUCCESS'
export const REQUEST_FREIGHTS_ERROR = 'REQUEST_FREIGHTS_ERROR'

//---------------------- CBOT
export const REQUEST_CBOTS = 'REQUEST_CBOTS'
export const REQUEST_CBOTS_SUCCESS = 'REQUEST_CBOTS_SUCCESS'
export const REQUEST_CBOTS_ERROR = 'REQUEST_CBOTS_ERROR'
export const REQUEST_DELETE_CBOT = 'REQUEST_DELETE_CBOT'
export const REQUEST_DELETE_CBOT_SUCCESS = 'REQUEST_DELETE_CBOT_SUCCESS'
export const REQUEST_DELETE_CBOT_ERROR = 'REQUEST_DELETE_CBOT_ERROR'

//---------------------- SignalR
export const REQUEST_SIGNALR_CONNECTION = 'REQUEST_SIGNALR_CONNECTION'
export const REQUEST_SIGNALR_CONNECTION_SUCCESS = 'REQUEST_SIGNALR_CONNECTION_SUCCESS'
export const REQUEST_SIGNALR_CONNECTION_ERROR = 'REQUEST_SIGNALR_CONNECTION_ERROR'

export const CLOSE_SIGNALR_CONNECTION = 'CLOSE_SIGNALR_CONNECTION'
export const CLOSE_SIGNALR_CONNECTION_SUCCESS = 'CLOSE_SIGNALR_CONNECTION_SUCCESS'
export const CLOSE_SIGNALR_CONNECTION_ERROR = 'CLOSE_SIGNALR_CONNECTION_ERROR'

export const CHECK_CMA_CONNECTION = 'CHECK_CMA_CONNECTION'
export const CHECK_CMA_CONNECTION_SUCCESS = 'CHECK_CMA_CONNECTION_SUCCESS'
export const CHECK_CMA_CONNECTION_ERROR = 'CHECK_CMA_CONNECTION_ERROR'

export const SUBSCRIBE_COTATIONS = 'SUBSCRIBE_COTATIONS'
export const SUBSCRIBE_COTATIONS_SUCCESS = 'SUBSCRIBE_COTATIONS_SUCCESS'
export const SUBSCRIBE_COTATIONS_ERROR = 'SUBSCRIBE_COTATIONS_ERROR'

export const SUBSCRIBE_EXCHANGES = 'SUBSCRIBE_EXCHANGES'
export const SUBSCRIBE_EXCHANGES_SUCCESS = 'SUBSCRIBE_EXCHANGES_SUCCESS'
export const SUBSCRIBE_EXCHANGES_ERROR = 'SUBSCRIBE_EXCHANGES_ERROR'

export const SET_EXCHANGES_INFO = 'SET_EXCHANGES_INFO'

export const UNSUBSCRIBE_COTATIONS = 'UNSUBSCRIBE_COTATIONS'
export const UNSUBSCRIBE_COTATIONS_SUCCESS = 'UNSUBSCRIBE_COTATIONS_SUCCESS'
export const UNSUBSCRIBE_COTATIONS_ERROR = 'UNSUBSCRIBE_COTATIONS_ERROR'

export const UNSUBSCRIBE_EXCHANGES = 'UNSUBSCRIBE_EXCHANGES'
export const UNSUBSCRIBE_EXCHANGES_SUCCESS = 'UNSUBSCRIBE_EXCHANGES_SUCCESS'
export const UNSUBSCRIBE_EXCHANGES_ERROR = 'UNSUBSCRIBE_EXCHANGES_ERROR'

export const UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES = 'UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES'
export const UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES_SUCCESS = 'UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES_SUCCESS'
export const UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES_ERROR = 'UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES_ERROR'

export const HANDLE_SIGNAL_R_VALUE_CHANGE = 'HANDLE_SIGNAL_R_VALUE_CHANGE'