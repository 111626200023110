import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import { bindActionCreators } from "redux";
import { authActions } from "../../Actions";
import { AuthService } from "../../Services";
import Loader from "react-loader";
import LoginForm from "./loginForm";

const authService = new AuthService();

const LoginContainer = (props) => {
  const auth = useSelector((state) => state.auth);

  function handleLogin(data) {
    props.authActions.requestLogin(data);
  }

  return authService.isAuthenticated() ? (
    <Redirect to="/" />
  ) : (
    <div className="login-page">
      <div className="login-container">
        <div className="inner-login-container">
          <div className="logo-container">
            <i className="ic-logo-orange"></i>
          </div>
          <div className="p-5 login-card">
            <Loader loaded={!auth.isFetching} color="#fff">
              <LoginForm handleLogin={handleLogin} />
            </Loader>
          </div>
          <div className="d-flex pb-5">
            <h3 className="m-0 register-login">
              Não tem uma conta? <a href="#/register">Cadastre-se</a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(LoginContainer);
