import React from "react";
import { logo } from "../../Assets";
import { useSelector, useDispatch } from "react-redux";
import UserPreRegisterForm from "./userPreRegisterForm";
import { userPreRegisterFormActions } from "../../Actions";
import { SuccessMessageModal } from "../../Components";

export default function RegisterContainer() {
  const dispatch = useDispatch();
  const userPreRegisterForm = useSelector((state) => state.userPreRegisterForm);

  const activityTypeOptions = [
    {
      value: 1,
      label: "Cooperativa",
      iconClass: "ic-handshake",
    },
    {
      value: 2,
      label: "Cerealista",
      iconClass: "ic-pack",
    },
    {
      value: 3,
      label: "Industria",
      iconClass: "ic-industry",
    },
  ];

  const preRegisterSaveHandler = (preRgisterForm) => {
    dispatch(userPreRegisterFormActions.handleSave(preRgisterForm));
  };

  return (
    <div className="register-container-background">
      <div className="register-container">
        <div className="logo-row">
          <img src={logo} alt="Brick Logo" />
        </div>
        {userPreRegisterForm.isSaveComplete ? (
          <div className="success-msg-container">
            <SuccessMessageModal
              message={
                <span>
                  SUA SOLICITAÇÃO FOI EFETUADA
                  <br /> COM SUCESSO!
                </span>
              }
              description={
                <span>
                  Aguarde, seu cadastro está em análise
                  <br /> e em breve entraremos em contato.
                </span>
              }
              btnText="Voltar"
              redirectLink="/"
            />
          </div>
        ) : (
          <>
            <div className="title-row">
              <span>Cadastre-se</span>
            </div>
            <UserPreRegisterForm
              isSaving={userPreRegisterForm.isSaving}
              fields={userPreRegisterForm}
              onSavePreRegister={preRegisterSaveHandler}
              activityTypeOptions={activityTypeOptions}
            />
          </>
        )}
      </div>{" "}
    </div>
  );
}
