import * as types from './actionTypes'
import { HarvestService, alertService } from '../Services'

const harvestService = new HarvestService();

export const requestHarvests = () => ({
    type: types.REQUEST_HARVESTS,
    payload: null
});

export const requestHarvestsSuccess = harvests => ({
    type: types.REQUEST_HARVESTS_SUCCESS,
    payload: harvests
});

export const requestHarvestsError = error => ({
    type: types.REQUEST_HARVESTS_ERROR,
    payload: error
});

export const fetchHarvests = () => dispatch => {
    dispatch(requestHarvests());
    harvestService
        .findAll()
        .then(response => {
            const harvests = response.data
            const harvestsOptions = harvests.map(harvest => {
                return {
                    value: harvest.year,
                    label: harvest.year,
                    productId: harvest.productId
                }
            })

            dispatch(requestHarvestsSuccess({ entities: harvests, options: harvestsOptions }));
        })
        .catch(error => {
            dispatch(requestHarvestsError(error));
            alertService.error(error, 'Erro ao buscar safras');
        });
};

export const requestDeleteHarvest = () => ({
    type: types.REQUEST_DELETE_HARVEST,
    payload: null
});

export const requestDeleteHarvestSuccess = () => ({
    type: types.REQUEST_DELETE_HARVEST_SUCCESS,
    payload: null
});

export const requestDeleteHarvestError = error => ({
    type: types.REQUEST_DELETE_HARVEST_ERROR,
    payload: error
});

export const deleteHarvest = (id, hideModal) => dispatch => {
    dispatch(requestDeleteHarvest());
    harvestService.delete(id)
        .then(() => {
            dispatch(requestDeleteHarvestSuccess())
            hideModal()
            dispatch(fetchHarvests())
            alertService.success('Safra excluída com sucesso!')
        }).catch(error => {
            dispatch(requestDeleteHarvestError(error))
            alertService.error(error, 'Erro ao excluir safra')
        })
};

export const requestHarvestOptions = () => ({
    type: types.REQUEST_HARVEST_OPTIONS,
    payload: null
});

export const requestHarvestOptionsSuccess = harvests => ({
    type: types.REQUEST_HARVEST_OPTIONS_SUCCESS,
    payload: harvests
});

export const requestHarvestOptionsError = error => ({
    type: types.REQUEST_HARVEST_OPTIONS_ERROR,
    payload: error
});

export const fetchHarvestOptions = () => dispatch => {
    dispatch(requestHarvestOptions());
    harvestService
        .findAll()
        .then(response => {
            if (response.data) {
                const entities = response.data;
                const options = entities.map(harvest => {
                    return {
                        value: harvest.id,
                        label: harvest.year,
                        productId: harvest.productId
                    };
                });
                dispatch(requestHarvestOptionsSuccess(options));
            }
        })
        .catch(error => {
            dispatch(requestHarvestOptionsError(error));
            alertService.error(error, 'Erro ao buscar safras');
        });
};
