import React from 'react'
import TabItem from './tabItem'

export default function Tab({ harvestGroup, currentTab, onTabClick, maxRegularTabs }) {
    // const size = harvestGroup.length;

    return (
        <>
            <div className='tab-container'>
                {harvestGroup.map(harvest =>
                    <TabItem
                        key={harvest.id}
                        id={harvest.id}
                        productId={harvest.productId}
                        product={harvest.productName}
                        year={harvest.year}
                        isActive={currentTab === harvest.id}
                        size="regular"
                        onTabClick={onTabClick}
                    />)}
            </div>
            <hr className='harvest-product-tab-border mb-4' />
        </>
    )
}
