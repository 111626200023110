import { NotificationService, alertService } from '../Services'
import * as types from './actionTypes'
import { dateUtils } from '../Utils';

const notificationService = new NotificationService();

export const requestNotifications = () => ({
    type: types.REQUEST_NOTIFICATIONS,
    payload: null
});

export const requestNotificationsSuccess = notifications => ({
    type: types.REQUEST_NOTIFICATIONS_SUCCESS,
    payload: notifications
});

export const requestNotificationsError = error => ({
    type: types.REQUEST_NOTIFICATIONS_ERROR,
    payload: error
});

export const fetchNotifications = (sendToast = false) => dispatch => {
    dispatch(requestNotifications());
    notificationService
        .findAllSelf({ createBegin: dateUtils.getYesterday() })
        .then(response => {
            dispatch(requestNotificationsSuccess(response.data))
            // debugger
            // const unreadNotifications = response.data.filter(notification => !notification.read)
            // if (sendToast && unreadNotifications.length > 0) {
            //     alertService.infoRedirect(unreadNotifications[0].description, '#/myNegotiations')
            // }
        }
        )
        .catch(error => {
            dispatch(requestNotificationsError(error));
            alertService.error(error, 'Erro ao buscar notificações')
        });
};

export const requestNotificationRead = () => ({
    type: types.REQUEST_NOTIFICATION_READ,
    payload: null
});

export const requestNotificationReadSuccess = notification => ({
    type: types.REQUEST_NOTIFICATION_READ_SUCCESS,
    payload: notification
});

export const requestNotificationReadError = error => ({
    type: types.REQUEST_NOTIFICATION_READ_ERROR,
    payload: error
});

export const readHandler = id => dispatch => {
    dispatch(requestNotificationRead());
    notificationService
        .markAsRead(id)
        .then(response => {
            dispatch(requestNotificationReadSuccess(response.data));
        }
        )
        .catch(error => {
            dispatch(requestNotificationReadError(error));
            alertService.error(error, 'Erro ao buscar notificações');
        });
};
