import React from "react";
import ReactDOM from "react-dom";
import Loader from "react-loader";

export default function Modal({
  isShowing,
  hide,
  content,
  btnClass,
  btnTxt,
  onClick,
  isLoading = false,
  hideClose = false,
  cancelBtnTxt = "Cancelar",
}) {
  return isShowing
    ? ReactDOM.createPortal(
        <>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex="-1"
            role="dialog"
          >
            <div className="modal-container">
              <div className="pointer-events-auto p-5 text-center">
                <Loader loaded={!isLoading} color="#fff">
                  <div className="modal-header mb-5">
                    {hideClose ? null : (
                      <button
                        type="button"
                        className="modal-close-button"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={hide}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    )}
                  </div>
                  {typeof content === "string" ? (
                    <>
                      <span className="modal-content-text">{content}</span>
                      <div className="mt-4">
                        <button
                          className="btn inova-btn secondary mr-3"
                          onClick={hide}
                        >
                          {cancelBtnTxt}
                        </button>
                        <button
                          className={`btn inova-btn ${btnClass}`}
                          onClick={onClick}
                        >
                          {btnTxt}
                        </button>
                      </div>
                    </>
                  ) : (
                    content
                  )}
                </Loader>
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;
}
