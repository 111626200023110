import React from 'react'
import Loadable from 'react-loadable'
import Loader from 'react-loader'
import { userDataStorageUtils, redirectUtils } from '../Utils'

function Loading() {
  return <Loader loaded={false} color='#fff' />;
}

const PricesIndications = Loadable({
  loader: () => import('../Modules/PricesIndications/pricesIndications.container'),
  loading: Loading
});

const OfferCreator = Loadable({
  loader: () => import('../Modules/Offer/offerCreator.container'),
  loading: Loading
});

const CounterOffer = Loadable({
  loader: () => import('../Modules/Offer/CounterOffer/counterOffer.container'),
  loading: Loading
})

const Negotiations = Loadable({
  loader: () => import('../Modules/Negotiations/negotiations.container'),
  loading: Loading
})

const NegotiationDetail = Loadable({
  loader: () => import('../Modules/Negotiations/Details/negotiationDetails.container'),
  loading: Loading
})

const ChangePassword = Loadable({
  loader: () => import('../Modules/ChangePass/changePass.container'),
  loading: Loading
})

const TermsContainer = Loadable({
  loader: () => import('../Modules/Terms/terms.container'),
  loading: Loading
})

const Logout = Loadable({
  loader: () => {
    userDataStorageUtils.removeAuth()
    redirectUtils.redirectTo('#/')
  },
  loading: Loading
})

const Routes = [
  {
    path: '/',
    exact: true,
    component: PricesIndications
  },
  {
    path: '/offer',
    exact: true,
    name: 'Criador de Oferta',
    component: OfferCreator
  },
  {
    path: '/counterOffer/:id',
    name: 'Contra Oferta',
    component: CounterOffer
  },
  {
    path: '/myNegotiations',
    exact: true,
    name: 'Minhas Negociações',
    component: Negotiations
  },
  {
    path: '/myNegotiations/:id',
    exact: true,
    name: 'Minhas Negociações - Detalhe',
    component: NegotiationDetail
  },
  {
    path: '/changePassword',
    exact: true,
    name: 'Mudar Senha',
    component: ChangePassword
  },
  {
    path: '/terms',
    exact: true,
    name: 'Termos de Uso e Política de Privacidade',
    component: TermsContainer
  },
  { path: '/logout', name: 'Logout', component: Logout }
]

export default Routes;
