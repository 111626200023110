import initialState from '../initialState';
import { REQUEST_SAVE_OFFER_FORM, REQUEST_SAVE_OFFER_FORM_SUCCESS, REQUEST_SAVE_OFFER_FORM_ERROR, CLEAR_OFFER_FORM, HANDLE_OFFER_FORM_VALUE_CHANGE } from '../../Actions/actionTypes';


const requestSaveOfferForm = (state, action) => {
    return { ...state, isSaving: true };
};

const requestSaveOfferFormSuccess = (state, action) => {
    return { ...initialState.offerForm, isSaveComplete: true };
};

const requestSaveOfferFormError = (state, action) => {
    return { ...state, isSaving: false, ...action.payload.data };
};

const handleValueChange = (state, action) => {
    return {
        ...state,
        ...action.payload,
    };
};

const clearOfferForm = (state, action) => {
    return initialState.offerForm
}

const actions = {
    [REQUEST_SAVE_OFFER_FORM]: requestSaveOfferForm,
    [REQUEST_SAVE_OFFER_FORM_SUCCESS]: requestSaveOfferFormSuccess,
    [REQUEST_SAVE_OFFER_FORM_ERROR]: requestSaveOfferFormError,
    [CLEAR_OFFER_FORM]: clearOfferForm,
    [HANDLE_OFFER_FORM_VALUE_CHANGE]: handleValueChange
};

export const offerForm = (state = initialState.offerForm, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
