import BaseService from './base.service'
import { userDataStorageUtils } from '../../Utils'

class AuthService extends BaseService {
  constructor() {
    super('Login/');
  }

  isAuthenticated() {
    const auth = userDataStorageUtils.getAuth()
    return !!auth
  }
}

export default AuthService;
