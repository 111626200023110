import initialState from './initialState'
import { 
    REQUEST_ACTIVITY_TYPES,
    REQUEST_ACTIVITY_TYPES_SUCCESS,
    REQUEST_ACTIVITY_TYPES_ERROR 
} from '../Actions/actionTypes'

const requestActivityTypes = (state, action) => {
    return { ...state, isFetching: true };
};

const requestActivityTypesSuccess = (state, action) => {
    return {
        ...state,
        options: action.payload,
        isFetching: false
    };
};

const requestActivityTypesError = (state, action) => {
    return { ...state, isFetching: false };
};

const actions = {
    [REQUEST_ACTIVITY_TYPES]: requestActivityTypes,
    [REQUEST_ACTIVITY_TYPES_SUCCESS]: requestActivityTypesSuccess,
    [REQUEST_ACTIVITY_TYPES_ERROR]: requestActivityTypesError
};

export const activityTypes = (state = initialState.activityTypes, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
