import initialState from '../initialState'
import {
    REQUEST_OFFERS_HISTORY,
    REQUEST_OFFERS_HISTORY_SUCCESS,
    REQUEST_OFFERS_HISTORY_ERROR,
    HANDLE_OFFERS_HISTORY_VALUE_CHANGE
} from '../../Actions/actionTypes'

const requestOffersHistory = (state, action) => {
    return { ...state, ...action.payload, isFetching: true };
};

const requestOffersHistorySuccess = (state, action) => {
    return {
        ...state,
        entities: action.payload && action.payload.results,
        count: action.payload.totalResults,
        isFetching: false
    };
};

const requestOffersHistoryError = (state, action) => {
    return { ...state, isFetching: false };
};

const handleValueChange = (state, action) => {
    return { ...state, ...action.payload, };
};

const actions = {
    [REQUEST_OFFERS_HISTORY]: requestOffersHistory,
    [REQUEST_OFFERS_HISTORY_SUCCESS]: requestOffersHistorySuccess,
    [REQUEST_OFFERS_HISTORY_ERROR]: requestOffersHistoryError,
    [HANDLE_OFFERS_HISTORY_VALUE_CHANGE]: handleValueChange
};

export const offersHistory = (state = initialState.offersHistory, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
