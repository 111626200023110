import React, { useEffect } from 'react'
import { logo } from '../../Assets'
import FirstAccessForm from './firstAccessForm'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../../Actions'
import { Redirect } from 'react-router-dom'
import Loader from 'react-loader'

export default function FirstAccessContainer() {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    useEffect(() => {
        dispatch(authActions.fetchTermsOfService({ ...auth }))

        // eslint-disable-next-line
    }, [auth.termsOfService, dispatch])

    return (
        <div className='first-access-container'>
            <div className='logo-row'>
                <img src={logo} alt='Brick Logo' />
            </div>
            {
                auth.termsOfServiceAllowed === false || auth.passwordTemporary === false ?
                    <div className='tc-container'>
                        <div className='title-row'>
                            <span>Termos de Uso e Política de Privacidade</span>
                        </div>
                        <Loader loaded={!auth.isFetching} color='#fff'>
                            <div className='use-terms-section'>
                                <div className='content' dangerouslySetInnerHTML={{ __html: auth.termsOfService }}>
                                </div>
                                <div className='btn-row'>
                                    <button className='btn inova-btn primary' onClick={() => dispatch(authActions.requestAcceptanceTermsOfService({ ...auth }))} disabled={auth.isFetching}>ACEITO OS TERMOS DE USO</button>
                                </div>
                            </div>
                        </Loader>
                    </div> : (auth.termsOfServiceAllowed === undefined && auth.passwordTemporary === undefined) ?
                        <Redirect to='/' /> : <>
                            <div className='title-row'>
                                <span>Primeiro Acesso</span>
                            </div>
                            <FirstAccessForm onFirstAccess={e => dispatch(authActions.requestFirstAccess({ ...e, ...auth }))} isFetching={auth.isFetching} />
                        </>
            }
        </div>
    )
}
