import { ActivityTypeService, alertService } from '../Services'
import * as types from './actionTypes'

const activityTypeService = new ActivityTypeService();

export const requestActivityTypes = () => ({
    type: types.REQUEST_ACTIVITY_TYPES,
    payload: null
});

export const requestActivityTypesSuccess = activityTypes => ({
    type: types.REQUEST_ACTIVITY_TYPES_SUCCESS,
    payload: activityTypes
});

export const requestActivityTypesError = error => ({
    type: types.REQUEST_ACTIVITY_TYPES_ERROR,
    payload: error
});

export const fetchActivityTypes = () => dispatch => {
    dispatch(requestActivityTypes());
    activityTypeService
        .findAllGet()
        .then(response => {
            if (response.data) {
                const activityTypes = response.data;
                const activityTypesOptions = activityTypes.map(activityType => {
                    return {
                        value: activityType.id,
                        label: activityType.name
                    };
                });

                dispatch(requestActivityTypesSuccess(activityTypesOptions));
            }
        })
        .catch(error => {
            dispatch(requestActivityTypesError(error));
            alertService.error(error, 'Erro ao buscar ramo de atividades');
        });
};