import BaseService from './base.service'
class TradeService extends BaseService {
    constructor() {
        super('Trade');
    }
    getConfirmationByOffer(offerId) {
        return this.get({}, `/ConfirmationByOffer/${offerId}`)
    }
    updateAndConfirm(offerId, data) {
        return this.post(data, `/UpdateAndConfirm/${offerId}`)
    }
    getNegotiationContract(contractURL) {
        return this.getFileWithUrl(contractURL)
    }
    resendContractEmail(tradeId) {
        return this.post({}, `/ResendContractEmail/${tradeId}`)
    }
}
export default TradeService;