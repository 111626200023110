import initialState from './initialState';
import { REQUEST_CITIES, REQUEST_CITIES_SUCCESS, REQUEST_CITIES_ERROR } from '../Actions/actionTypes';

const requestCities = (state, action) => {
    return {
        ...state,
        isFetching: true
    };
};

const requestCitiesSuccess = (state, action) => {
    return {
        ...state,
        ...action.payload,
        isFetching: false
    };
};

const requestCitiesError = (state, action) => {
    return {
        ...state,
        isFetching: false
    };
};

const actions = {
    [REQUEST_CITIES]: requestCities,
    [REQUEST_CITIES_SUCCESS]: requestCitiesSuccess,
    [REQUEST_CITIES_ERROR]: requestCitiesError
};

export const cities = (state = initialState.cities, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
