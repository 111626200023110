import * as types from './actionTypes'

export const requestConnection = () => ({
    type: types.REQUEST_SIGNALR_CONNECTION,
    payload: null
});

export const requestConnectionSuccess = () => ({
    type: types.REQUEST_SIGNALR_CONNECTION_SUCCESS,
    payload: null
});

export const requestConnectionError = error => ({
    type: types.REQUEST_SIGNALR_CONNECTION_ERROR,
    payload: error
});

export const checkCMAConnection = () => ({
    type: types.CHECK_CMA_CONNECTION,
    payload: null
})

export const checkCMAConnectionSuccess = isConnected => ({
    type: types.CHECK_CMA_CONNECTION_SUCCESS,
    payload: isConnected
})

export const checkCMAConnectionError = error => ({
    type: types.CHECK_CMA_CONNECTION_ERROR,
    payload: error
})

export const subscribeCotations = (entities, controlVariable) => ({
    type: types.SUBSCRIBE_COTATIONS,
    payload: { entities, controlVariable }
})

export const subscribeCotationsSuccess = controlVariable => ({
    type: types.SUBSCRIBE_COTATIONS_SUCCESS,
    payload: controlVariable
})

export const subscribeCotationsError = controlVariable => ({
    type: types.SUBSCRIBE_COTATIONS_ERROR,
    payload: controlVariable
})

export const subscribeExchanges = (entities, controlVariable) => ({
    type: types.SUBSCRIBE_EXCHANGES,
    payload: { entities, controlVariable }
})

export const subscribeExchangesSuccess = controlVariable => ({
    type: types.SUBSCRIBE_EXCHANGES_SUCCESS,
    payload: controlVariable
})

export const subscribeExchangesError = controlVariable => ({
    type: types.SUBSCRIBE_EXCHANGES_ERROR,
    payload: controlVariable
})

export const closeConnection = () => ({
    type: types.CLOSE_SIGNALR_CONNECTION,
    payload: null
});

export const closeConnectionSuccess = () => ({
    type: types.CLOSE_SIGNALR_CONNECTION_SUCCESS,
    payload: null
});

export const closeConnectionError = () => ({
    type: types.CLOSE_SIGNALR_CONNECTION_ERROR,
    payload: null
});

export const setExchangesInfo = exchangesInfo => ({
    type: types.SET_EXCHANGES_INFO,
    payload: exchangesInfo
})

export const unsubscribeCotations = (entities, controlVariable) => ({
    type: types.UNSUBSCRIBE_COTATIONS,
    payload: { entities, controlVariable }
})

export const unsubscribeCotationsSuccess = controlVariable => ({
    type: types.UNSUBSCRIBE_COTATIONS_SUCCESS,
    payload: controlVariable
})

export const unsubscribeCotationsError = controlVariable => ({
    type: types.UNSUBSCRIBE_COTATIONS_ERROR,
    payload: controlVariable
})

export const unsubscribeExchanges = (entities, controlVariable) => ({
    type: types.UNSUBSCRIBE_EXCHANGES,
    payload: { entities, controlVariable }
})

export const unsubscribeExchangesSuccess = controlVariable => ({
    type: types.UNSUBSCRIBE_EXCHANGES_SUCCESS,
    payload: controlVariable
})

export const unsubscribeExchangesError = controlVariable => ({
    type: types.UNSUBSCRIBE_EXCHANGES_ERROR,
    payload: controlVariable
})

export const unsubscribeAllCotationsAndExchanges = () => ({
    type: types.UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES,
    payload: null
})
export const unsubscribeAllCotationsAndExchangesSuccess = () => ({
    type: types.UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES_SUCCESS,
    payload: null
})
export const unsubscribeAllCotationsAndExchangesError = () => ({
    type: types.UNSUBSCRIBE_ALL_COTATIONS_AND_EXCHANGES_ERROR,
    payload: null
})

export const handleValueChange = edition => ({
    type: types.HANDLE_SIGNAL_R_VALUE_CHANGE,
    payload: edition
});
