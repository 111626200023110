import initialState from "../initialState";
import {
  HANDLE_PRICES_INDICATIONS_VALUE_CHANGE,
  REQUEST_PUBLISHED_PRICES,
  REQUEST_PUBLISHED_PRICES_SUCCESS,
  REQUEST_PUBLISHED_PRICES_ERROR,
  REQUEST_UNPUBLISH_PRICE,
  REQUEST_UNPUBLISH_PRICE_SUCCESS,
  REQUEST_UNPUBLISH_PRICE_ERROR,
  REQUEST_UPDATE_PRICE_VALUE,
  REQUEST_UPDATE_PRICE_VALUE_SUCCESS,
  REQUEST_UPDATE_PRICE_VALUE_ERROR,
  PRICE_INDICATIONS_COTATION_UPDATE,
  PRICE_INDICATIONS_EXCHANGE_UPDATE,
} from "../../Actions/actionTypes";
import { calculateUpdatedValue } from "../../Utils";

const handleValueChange = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const requestPublishedPrices = (state, action) => {
  return { ...state, isFetching: true, entities: [] };
};

const requestPublishedPricesSuccess = (state, action) => {
  return {
    ...state,
    entities: action.payload,
    isFetching: false,
  };
};

const requestPublishedPricesError = (state, action) => {
  return { ...state, isFetching: false };
};

const requestUnpublishPrice = (state, action) => {
  return { ...state, isSaving: true };
};

const requestUnpublishPriceSuccess = (state, action) => {
  return { ...state, isSaving: false };
};

const requestUnpublishPriceError = (state, action) => {
  return { ...state, isSaving: false };
};

const requestUpdatePriceValue = (state, action) => {
  return { ...state, isSaving: true };
};

const requestUpdatePriceValueSuccess = (state, action) => {
  return { ...state, isSaving: false };
};

const requestUpdatePriceValueError = (state, action) => {
  return { ...state, isSaving: false };
};

const cotationUpdate = (state, action) => {
  const updatedCotation = action.payload.cotation;
  const entities = state.entities.map((indication) => {
    if (indication.cbotId === action.payload.cbotId) {
      return {
        ...indication,
        updatedCotation,
        updatedValue: calculateUpdatedValue(
          indication,
          updatedCotation,
          indication.updatedExchangeFee
        ),
      };
    }
    return indication;
  });
  return { ...state, entities };
};

const exchangeUpdate = (state, action) => {
  const updatedExchangeFee = action.payload.exchange;
  const entities = state.entities.map((indication) => {
    if (indication.paymentOn === action.payload.date) {
      return {
        ...indication,
        updatedExchangeFee,
        updatedValue: calculateUpdatedValue(
          indication,
          indication.updatedCotation,
          updatedExchangeFee
        ),
      };
    }
    return indication;
  });
  return { ...state, entities };
};

const actions = {
  [HANDLE_PRICES_INDICATIONS_VALUE_CHANGE]: handleValueChange,
  [REQUEST_PUBLISHED_PRICES]: requestPublishedPrices,
  [REQUEST_PUBLISHED_PRICES_SUCCESS]: requestPublishedPricesSuccess,
  [REQUEST_PUBLISHED_PRICES_ERROR]: requestPublishedPricesError,
  [REQUEST_UNPUBLISH_PRICE]: requestUnpublishPrice,
  [REQUEST_UNPUBLISH_PRICE_SUCCESS]: requestUnpublishPriceSuccess,
  [REQUEST_UNPUBLISH_PRICE_ERROR]: requestUnpublishPriceError,
  [REQUEST_UPDATE_PRICE_VALUE]: requestUpdatePriceValue,
  [REQUEST_UPDATE_PRICE_VALUE_SUCCESS]: requestUpdatePriceValueSuccess,
  [REQUEST_UPDATE_PRICE_VALUE_ERROR]: requestUpdatePriceValueError,
  [PRICE_INDICATIONS_COTATION_UPDATE]: cotationUpdate,
  [PRICE_INDICATIONS_EXCHANGE_UPDATE]: exchangeUpdate,
};

export const pricesIndications = (
  state = initialState.pricesIndications,
  action
) => {
  return actions[action.type] ? actions[action.type](state, action) : state;
};
